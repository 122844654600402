import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { H1, H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Tabs } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  useBrandBillingQuery,
  useCreateStripePortalMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { Integrations } from "./Integrations";
import { BrandProfile } from "./Profile";
import { BrandTeam } from "./Team";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

export interface MatchParams {
  page: string;
}

export const BrandSettings = () => {
  return (
    <Wrap>
      <ContentWrap>
        <H1 margin="0 0 s">Settings</H1>
        <Tabs
          labels={[
            { label: "Integrations", hash: "integrations" },
            { label: "Team", hash: "team" },
            { label: "Profile", hash: "profile" },
            { label: "Billing" },
          ]}
          content={[
            <TabWrap>
              <Integrations />
            </TabWrap>,
            <TabWrap>
              <BrandTeam />
            </TabWrap>,
            <TabWrap>
              <BrandProfile />
            </TabWrap>,
            <TabWrap>
              <BillingRedirect />
            </TabWrap>,
          ]}
        />
      </ContentWrap>
    </Wrap>
  );
};

// to fix an issue where the card borders are cut off
const TabWrap = styled.div`
  margin: 1px;
`;

const ContentWrap = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: ${(p) => p.theme.spacing.xxl};
`;

const BillingRedirect = () => {
  const client = useGqlClient();
  const createStripePortal = useCreateStripePortalMutation(client);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const [error, setError] = useState<string | null>(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data, isLoading } = useBrandBillingQuery(client, {
    brandID: activeBrandId ? activeBrandId : "",
  });

  useEffect(() => {
    if (data?.brand?.stripeCustomerId && !isRedirecting) {
      setIsRedirecting(true);
      createStripePortal.mutate(
        {},
        {
          onSuccess: (res: any) => {
            window.location.href = res.createStripePortal.redirectUrl;
          },
          onError: (error: any) => {
            setError("Something went wrong");
            setIsRedirecting(false);
          },
        }
      );
    }
  }, [data?.brand?.stripeCustomerId, createStripePortal, isRedirecting]);

  if (isLoading || isRedirecting || createStripePortal.isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Wrap>
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your billing information, please try again later.
        </Text>
      </Wrap>
    );
  }

  if (data && data.brand && !data.brand.stripeCustomerId) {
    return <Redirect to="/b/billing" />;
  }

  return <Loading />;
};
