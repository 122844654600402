import {
  addMinutes,
  format,
  fromUnixTime,
  getUnixTime,
  isToday,
  isYesterday,
  subDays,
} from "date-fns";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  ConversationMessagesDocument,
  ConversationMessagesQuery,
  useCreateMessageMutation,
  useGetOrCreateConversationQuery,
  useMarkMessageAsReadMutation,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { useTheme } from "../hooks/useTheme";
import { authSelectors } from "../store/auth/selector";
import styled, { css } from "../styles";
import { demoBookingConfig } from "../utils/demoBookingConfig";
import Avatar from "./Avatar";
import { Flex } from "./Flex";
import { SendMessageIcon } from "./icons/chat/SendMessageIcon";
import Loading from "./Loading";
import { Text } from "./Text";
import { View } from "./View";

const UPLOAD_BUTTON_HEIGHT = 32;
const AVATAR_SIZE = 30;

const Chat = ({
  brandId,
  creatorId,
  bookingId,
}: {
  brandId: string;
  creatorId: string;
  bookingId?: string;
}) => {
  const [message, setMessage] = useState("");
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const client = useGqlClient();
  const account = useSelector(authSelectors.account);
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const firstMessage = React.useMemo(() => subDays(new Date(), 1), []);

  // Observer ref for infinite scrolling
  const observer = useRef<IntersectionObserver | null>(null);

  // Add state to track messages marked as read
  const [markedAsReadIds, setMarkedAsReadIds] = useState<Set<string>>(
    new Set()
  );

  // Add the mark message as read mutation
  const { mutate: markMessageAsRead } = useMarkMessageAsReadMutation(client);

  // Observer for tracking message visibility to mark as read
  const messageObserver = useRef<IntersectionObserver | null>(null);

  const demoModeEnabled = useFeatureFlagEnabled("demo-mode");

  // Check if we're in demo mode for this brand
  let isDemoMode =
    demoModeEnabled && demoBookingConfig.some((brand) => brand.id === brandId);

  // Demo messages definition (unchanged)
  const MESSAGES = [
    {
      id: "2",
      message: "Hey 👋",
      createdAt: getUnixTime(firstMessage),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "1",
      message: "Hello, how are you?",
      createdAt: getUnixTime(addMinutes(firstMessage, 24)),
      type: "text",
      account: {
        id: account?.id,
        firstName: account?.firstName,
        lastName: account?.lastName,
      },
    },
    {
      id: "2",
      message: "I'm good, thank you!",
      createdAt: getUnixTime(addMinutes(firstMessage, 32)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "3",
      message: "",
      createdAt: getUnixTime(addMinutes(firstMessage, 67)),
      type: "attachment",
      attachmentUrl:
        "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2XLJ1BKLn4J2WVYO4Ehdy4NMoRx/18049245697929863.mp4",
      thumbnailUrl:
        "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2XLJ1BKLn4J2WVYO4Ehdy4NMoRx/18049245697929863.jpg",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "4",
      message: `*Caption:*\n\nNew seafood afternoon tea in London 🦞☕️ ⁣
⁣
Smith's is a part of a 4-storey restaurant and Grade II-listed building beside London's famous Smithfield Market offers prime cuts of meat and locally sourced seasonal produce including day boat fish. ⁣
⁣
This iconic restaurant brings the freshest seafood to the heart of the city. Set in a chic, industrial setting, the afternoon tea features an array of delicately prepared seafood options.⁣
⁣
The menu typically includes treats like oysters, prawns, clams and stuffed crab, all prepared with high-quality, sustainably sourced ingredients.⁣
⁣
To complete the experience, diners can pair their meal with a glass of champagne or opt for a selection of fine teas, ensuring a luxurious and refreshing afternoon. ⁣
⁣
£49pp (including champagne) ⁣
⁣
The bustling Smiths Restaurant at⁣
'Smiths' of Smithfield is a 4-minute walk from Farringdon Tube Station.⁣
⁣
📍 Smiths, 67-77 Charterhouse St, London EC1M 6HJ

#londyn #londonblogger #londontravel #afternoontea #london4all #londonlove #londonafternoontea #travelblogger #londonrooftops #cityoflondon"`,
      createdAt: getUnixTime(addMinutes(firstMessage, 98)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "2",
      message: "How's that ☝️",
      createdAt: getUnixTime(addMinutes(firstMessage, 120)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
  ] as ConversationMessagesQuery["conversationMessages"]["messages"] &
    {
      type: "text" | "attachment";
      attachmentUrl?: string;
      thumbnailUrl?: string;
    }[];

  // Create a sorted copy of MESSAGES instead of mutating the original array
  // Sort by createdAt to ensure chronological order (oldest first for initial display)
  const demoMessages = [...MESSAGES];

  const { data, isLoading } = useGetOrCreateConversationQuery(
    client,
    {
      brandId,
      creatorId,
      bookingId: bookingId ?? undefined,
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const { mutate: createMessage } = useCreateMessageMutation(client);

  const conversationId = data?.getOrCreateConversation?.conversation?.id;

  const fetchMessages = useCallback(
    async ({ pageParam = "" }) => {
      return client.request<ConversationMessagesQuery>(
        ConversationMessagesDocument,
        {
          token: pageParam,
          conversationID: conversationId || "",
          count: 20,
        }
      );
    },
    [client, data?.getOrCreateConversation?.conversation?.id]
  );

  const {
    data: messagesData,
    isLoading: messagesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: refetchMessages,
  } = useInfiniteQuery(
    ["conversationMessages", conversationId],
    fetchMessages,
    {
      enabled: !!conversationId && !isDemoMode,
      getNextPageParam: (lastPage) =>
        lastPage.conversationMessages.cursor || undefined,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
    }
  );

  // Get messages from query data or use demo messages in demo mode
  let messages =
    messagesData?.pages.flatMap((page) => page.conversationMessages.messages) ||
    [];

  // If in demo mode, use the demo messages instead
  if (isDemoMode) {
    messages = demoMessages;
  }

  // Set up the message visibility observer to mark messages as read
  const setupMessageVisibilityTracking = useCallback(() => {
    if (!account?.id || !conversationId || isDemoMode) return;

    // Clean up previous observer
    if (messageObserver.current) {
      messageObserver.current.disconnect();
    }

    // Create a new IntersectionObserver
    messageObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const messageId = entry.target.getAttribute("data-message-id");

            // Ensure we have a valid message ID and it's not already marked as read
            if (messageId && !markedAsReadIds.has(messageId)) {
              console.log(`Message ${messageId} is visible, marking as read`);

              // Mark message as read
              markMessageAsRead(
                { messageId },
                {
                  onSuccess: () => {
                    // Add to set of marked messages
                    setMarkedAsReadIds((prev) => new Set([...prev, messageId]));
                    console.log(
                      `Message ${messageId} marked as read successfully`
                    );

                    // Stop observing this message
                    messageObserver.current?.unobserve(entry.target);
                  },
                  onError: (error) => {
                    console.error(
                      `Failed to mark message ${messageId} as read:`,
                      error
                    );
                  },
                }
              );
            }
          }
        });
      },
      {
        threshold: 0.7, // 70% visible
        rootMargin: "0px",
      }
    );
  }, [
    account?.id,
    conversationId,
    isDemoMode,
    markMessageAsRead,
    markedAsReadIds,
  ]);

  // Apply the message visibility tracking
  useEffect(() => {
    setupMessageVisibilityTracking();

    return () => {
      if (messageObserver.current) {
        messageObserver.current.disconnect();
      }
    };
  }, [setupMessageVisibilityTracking]);

  const handleSendMessage = () => {
    if (isDemoMode) {
      // In demo mode, just clear the message input
      setMessage("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
      return;
    }

    if (!data?.getOrCreateConversation?.conversation?.id) {
      return;
    }

    if (message.trim()) {
      setMessage("");
      // Reset textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }

      createMessage(
        {
          conversationId: data.getOrCreateConversation.conversation.id,
          content: message,
        },
        {
          onSuccess: () => {
            refetchMessages();
          },
        }
      );
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Format date for headers (Today, Yesterday, or date)
  const formatDateHeader = (date: Date) => {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return format(date, "MMMM d, yyyy");
    }
  };

  // Group messages by date
  const groupMessagesByDate = () => {
    if (!messages || messages.length === 0) return [];

    const groups: { [key: string]: any[] } = {};

    // Process messages in the order they come from the backend (newest first)
    messages.forEach(
      (
        msg: ConversationMessagesQuery["conversationMessages"]["messages"][number]
      ) => {
        const messageDate = fromUnixTime(msg.createdAt);
        const dateKey = format(messageDate, "yyyy-MM-dd");

        if (!groups[dateKey]) {
          groups[dateKey] = [];
        }

        groups[dateKey].push(msg);
      }
    );

    // Convert to array of objects with date and messages
    const result = Object.entries(groups).map(([dateKey, messages]) => {
      return {
        dateKey,
        dateHeader: formatDateHeader(new Date(dateKey)),
        // Each day's messages keep newest-first order from backend
        messages,
      };
    });

    // Sort date groups with newest dates first for column-reverse layout
    result.sort(
      (a, b) => new Date(b.dateKey).getTime() - new Date(a.dateKey).getTime()
    );

    return result;
  };

  // For infinite scroll to fetch older messages when scrolling up
  const oldestMessageRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading || isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          console.log("Oldest visible message detected, fetching more history");
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, fetchNextPage, isFetchingNextPage]
  );

  // Initial height adjustment for textarea
  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  // Scroll to newest messages on initial load and when new messages are sent
  useEffect(() => {
    if (messagesEndRef.current && !isFetchingNextPage) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages?.length, isFetchingNextPage]);

  // Keep scroll position stable when loading older messages
  const prevMessagesLength = useRef(messages?.length || 0);
  useEffect(() => {
    // If we've loaded more (older) messages, maintain scroll position
    if (isFetchingNextPage && messages?.length > prevMessagesLength.current) {
      // With column-reverse, browser automatically maintains scroll position
      // when new content is added at the top (oldest messages)
    }
    prevMessagesLength.current = messages?.length || 0;
  }, [messages?.length, isFetchingNextPage]);

  if (!brandId || !creatorId) {
    return null;
  }

  if (!data || isLoading || messagesLoading) {
    return null;
  }

  return (
    <ChatContainer>
      <MessagesContainer ref={messagesContainerRef}>
        {/* This invisible div at the end allows us to scroll to the most recent messages */}
        <div ref={messagesEndRef} />

        {messages && messages.length > 0 ? (
          groupMessagesByDate().map((group, groupIndex) => (
            <GroupContainer key={group.dateKey}>
              <DateHeader>
                <Text
                  size="xs"
                  colorPreset="secondary"
                  weight="semi"
                  align="center"
                  style={{ userSelect: "none" }}
                >
                  {group.dateHeader}
                </Text>
              </DateHeader>

              <MessagesList>
                {group.messages.map(
                  (
                    msg: ConversationMessagesQuery["conversationMessages"]["messages"][number],
                    index: number
                  ) => {
                    const isSender = msg.account?.id === account?.id;
                    // With column-reverse and newest-first, we need to show avatar on the FIRST
                    // message from a sender in the group (visually the bottom one)
                    const isFirstNonSenderInGroup =
                      !isSender &&
                      (index === 0 ||
                        group.messages[index - 1]?.account?.id === account?.id);

                    // Attach the observer ref to the oldest message
                    // With our sorting and column-reverse, that's the first message in the last group
                    const isOldestVisibleMessage =
                      groupIndex === groupMessagesByDate().length - 1 &&
                      index === group.messages.length - 1;

                    // Add ref function to track message visibility for read tracking
                    const setMessageRef = (node: HTMLDivElement | null) => {
                      // If this is a message that needs to be marked as read (not from sender)
                      if (
                        !isSender &&
                        node &&
                        !markedAsReadIds.has(msg.id) &&
                        messageObserver.current
                      ) {
                        // Add data attribute for message ID
                        node.setAttribute("data-message-id", msg.id);
                        // Start observing this message
                        messageObserver.current.observe(node);
                      }

                      // If this is also the oldest message, set that ref too
                      if (isOldestVisibleMessage) {
                        oldestMessageRef(node);
                      }
                    };

                    return (
                      <MessageWrap
                        key={msg.id}
                        isSender={isSender}
                        ref={setMessageRef}
                      >
                        <MessageRow>
                          {!isSender && (
                            <AvatarWrap>
                              {isFirstNonSenderInGroup ? (
                                <Avatar
                                  size={AVATAR_SIZE}
                                  url={
                                    data.getOrCreateConversation?.conversation
                                      ?.creator?.preferredProfile?.avatar
                                  }
                                  borderSize={0}
                                  withShadow={false}
                                />
                              ) : (
                                <View />
                              )}
                            </AvatarWrap>
                          )}
                          <div>
                            <Flex
                              justify={isSender ? "flex-end" : "flex-start"}
                            >
                              <Text
                                size="xxs"
                                colorPreset="secondary"
                                margin="0 0 0 xs"
                                align={isSender ? "right" : "left"}
                              >
                                {format(fromUnixTime(msg.createdAt), "HH:mm")}
                              </Text>
                              {/* Show message status only for sender's messages */}
                              {isSender && (
                                <MessageStatusContainer>
                                  <MessageStatus
                                    isSent={true}
                                    readAt={msg.readAt ?? undefined}
                                  />
                                </MessageStatusContainer>
                              )}
                            </Flex>
                            <div>
                              <MessageBubble isSender={isSender}>
                                <Text
                                  size="s"
                                  margin="0 0 0"
                                  style={{ whiteSpace: "pre-wrap" }}
                                >
                                  {msg.message}
                                </Text>
                              </MessageBubble>
                            </div>
                          </div>
                        </MessageRow>
                      </MessageWrap>
                    );
                  }
                )}
              </MessagesList>
            </GroupContainer>
          ))
        ) : (
          <View style={{ flex: 1 }}>
            <DateHeader>
              <Text
                size="xs"
                colorPreset="secondary"
                weight="semi"
                align="center"
                style={{ userSelect: "none" }}
              >
                Today
              </Text>
            </DateHeader>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
                maxWidth: "70%",
                margin: "0 auto",
                flex: 1,
              }}
            >
              <Text
                size="s"
                margin="0 0 0"
                align="center"
                colorPreset="secondary"
              >
                {data.getOrCreateConversation?.conversation?.creator
                  ?.preferredProfile?.username
                  ? `No previous messages between you and @${data.getOrCreateConversation?.conversation?.creator?.preferredProfile?.username}`
                  : "No previous messages"}
              </Text>
            </View>
          </View>
        )}
        {/* Loading indicator for fetching older messages - at the top when using column-reverse */}
        {isFetchingNextPage && (
          <Flex justify="center" align="center">
            <Loading />
          </Flex>
        )}
      </MessagesContainer>
      <ChatFooter>
        <ChatInputWrapper isFocused={textAreaFocused}>
          <ChatInput
            ref={textareaRef}
            placeholder="Write a message..."
            value={message}
            onChange={handleMessageChange}
            onKeyDown={handleKeyPress}
            rows={4}
            onFocus={() => setTextAreaFocused(true)}
            onBlur={() => setTextAreaFocused(false)}
            autoFocus={true}
          />
          <MessageSendButton onClick={handleSendMessage}>
            <SendMessageIcon
              width={UPLOAD_BUTTON_HEIGHT}
              height={UPLOAD_BUTTON_HEIGHT}
              colorPreset="link"
            />
          </MessageSendButton>
        </ChatInputWrapper>
      </ChatFooter>
    </ChatContainer>
  );
};

export default Chat;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

const DateHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(p) => p.theme.spacing.l} 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background-color: ${(p) => p.theme.color.typography.secondary}20;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
    background-color: ${(p) => p.theme.color.card.background};
    padding: 0 ${(p) => p.theme.spacing.m};
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: ${(p) => p.theme.spacing.s};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l} 0;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.color.typography.secondary}30;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}50;
    opacity: 1;
  }

  /* Show scrollbar when hovering or actively scrolling */
  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover,
  &:active {
    scrollbar-color: ${(p) => p.theme.color.typography.secondary}30 transparent;
  }
`;

const MessageBubble = styled.div<{ isSender: boolean }>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  background-color: ${(p) =>
    p.isSender
      ? `${p.theme.color.primary}25`
      : `${p.theme.color.typography.secondary}18`};
  position: relative;
  word-break: break-word;
  overflow-wrap: break-word;

  ${(p) =>
    p.isSender
      ? css`
          border-bottom-right-radius: 0;
        `
      : css`
          border-bottom-left-radius: 0;
        `}
`;

const MessageWrap = styled.div<{ isSender: boolean }>`
  max-width: 80%;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  align-self: ${(p) => (p.isSender ? "flex-end" : "flex-start")};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const ChatFooter = styled.div`
  padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m}
    ${(p) => p.theme.spacing.m};
`;

const ChatInputWrapper = styled.div<{ isFocused: boolean }>`
  position: relative;
  width: 100%;
  border-radius: ${(p) => p.theme.misc.borderRadiusLarge};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.m};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.m};
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;

  ${(p) =>
    p.isFocused &&
    css`
      border-color: ${(p) => p.theme.color.primary};
      box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
      outline: none;
    `}
`;

const ChatInput = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  resize: none;
  min-height: 24px;
  max-height: 420px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.normal};
  color: ${(p) => p.theme.color.typography.text};
  line-height: 1.4;
  padding: 0;
  background: transparent;

  /* Custom scrollbar styling - smaller and subtle */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.color.typography.secondary}30;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}50;
    opacity: 1;
  }

  /* Show scrollbar when hovering or actively scrolling */
  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover,
  &:active {
    scrollbar-color: ${(p) => p.theme.color.typography.secondary}30 transparent;
  }
`;

const MessageSendButton = styled.div`
  margin-top: auto;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  transition: filter 0.15s ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

const MessageRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.xs};
  width: 100%;
`;

const AvatarWrap = styled.div`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  min-width: ${AVATAR_SIZE}px;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  display: flex;
  align-items: flex-end;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.l};
`;

const MessagesList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

// Update the MessageStatus component to use readAt field
const MessageStatus = ({
  readAt,
  isSent,
}: {
  readAt?: string | number;
  isSent?: boolean;
}) => {
  const theme = useTheme();
  // Don't show any status if the message isn't sent yet
  if (!isSent) return null;

  // Message is considered read if it has a readAt timestamp
  const isRead = !!readAt;

  // Color is blue if read, gray otherwise
  const color = isRead ? theme.color.primary : theme.color.typography.secondary;

  return (
    <span
      style={{
        color,
        marginLeft: "4px",
        display: "inline-block",
        width: "16px",
        height: "12px",
        position: "relative",
      }}
    >
      {isRead ? (
        // Double tick using absolute positioning for proper spacing
        <>
          {/* First tick */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: "12px",
              height: "12px",
              position: "absolute",
              left: "0",
              top: "0",
            }}
          >
            <path
              fillRule="evenodd"
              d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
              clipRule="evenodd"
            />
          </svg>
          {/* Second tick */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: "12px",
              height: "12px",
              position: "absolute",
              left: "4px", // 4px from left for 3px spacing between the ticks
              top: "0",
            }}
          >
            <path
              fillRule="evenodd"
              d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
              clipRule="evenodd"
            />
          </svg>
        </>
      ) : (
        // Single check for sent/delivered messages - centered
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style={{
            width: "12px",
            height: "12px",
            position: "absolute",
            left: "2px", // Center in the 16px container
            top: "0",
          }}
        >
          <path
            fillRule="evenodd"
            d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </span>
  );
};

// Add a status container for alignment
const MessageStatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
  height: 12px;
`;
