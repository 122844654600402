import { MOBILE_BREAKPOINT } from "../config";
import {
  GetBookingQuery,
  Platform,
  PreferredProfileType,
} from "../graphql/generated";
import useAnalytics from "../hooks/useAnalytics";
import { useWindowSize } from "../hooks/useWindowSize";
import styled from "../styles";
import Avatar from "./Avatar";
import { Flex } from "./Flex";
import { H3 } from "./Heading";
import { Text } from "./Text";
import { ExternalTextLink } from "./TextLink";

const LeftAlign = styled.div`
  text-align: left;
`;

function formatNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return num.toString();
}

const TruncatedH3 = styled(H3)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreferredProfile = (props: {
  creator: GetBookingQuery["booking"]["creator"];
  avatarSize?: number;
  platform?: Platform;
}) => {
  if (!props.creator) {
    return null;
  }

  const avatarUrl =
    props.platform === Platform.Instagram
      ? props.creator.instagram?.avatar
      : props.platform === Platform.Tiktok
      ? props.creator.tikTok?.avatar
      : props.creator.preferredProfile?.avatar;

  const username =
    props.platform === Platform.Instagram
      ? props.creator.instagram?.username
      : props.platform === Platform.Tiktok
      ? props.creator.tikTok?.username
      : props.creator.preferredProfile?.username;

  const name =
    props.platform === Platform.Instagram
      ? props.creator.instagram?.name
      : props.platform === Platform.Tiktok
      ? props.creator.tikTok?.displayName
      : props.creator.preferredProfile?.name;

  const followers =
    props.platform === Platform.Instagram
      ? props.creator.instagram?.followersCount
      : props.platform === Platform.Tiktok
      ? props.creator.tikTok?.followersCount
      : props.creator.preferredProfile?.followers;

  const permalink =
    props.platform === Platform.Instagram
      ? `https://www.instagram.com/${username}`
      : props.platform === Platform.Tiktok
      ? `https://www.tiktok.com/@${username}`
      : props.creator.preferredProfile?.permalink;

  const type =
    props.platform === Platform.Instagram
      ? PreferredProfileType.PreferredProfileTypeInstagram
      : props.platform === Platform.Tiktok
      ? PreferredProfileType.PreferredProfileTypeTiktok
      : props.creator.preferredProfile?.type;

  if (!username) {
    return null;
  }

  if (!followers) {
    return null;
  }

  if (!avatarUrl) {
    return null;
  }

  return (
    <AvatarWithUsername
      url={avatarUrl}
      username={username}
      size={props.avatarSize ? props.avatarSize : 58}
      permalink={permalink}
      type={type}
    />
  );
};

export const AvatarWithUsername = (props: {
  url: string;
  username: string;
  size?: number;
  permalink?: string;
  type?: PreferredProfileType;
  followers?: number;
}) => {
  const window = useWindowSize();
  const { track } = useAnalytics();

  const isMobile = window && window.width && window.width <= MOBILE_BREAKPOINT;

  return (
    <Flex margin="0 0 m 0" align="center">
      <Avatar
        margin="0 l 0 0"
        borderSize={0}
        size={props.size ? props.size : 58}
        withShadow={false}
        url={props.url}
      />
      <LeftAlign>
        <TruncatedH3 margin="0">{props.username}</TruncatedH3>
        <Flex direction="row" align="center">
          {props.username && (
            <ExternalTextLink
              target="_blank"
              href={props.permalink}
              rel="noreferrer"
              onClick={() =>
                track("Social media profile clicked", {
                  type: props.type,
                })
              }
            >
              @
              {isMobile && props.username.length > 18
                ? props.username.slice(0, 18) + "..."
                : props.username}
            </ExternalTextLink>
          )}
          {props.followers && (
            <Text
              size="s"
              colorPreset="secondary"
              margin="0 0 0 xs"
              style={{ marginTop: 1 }}
            >
              ({formatNumber(props.followers ?? 0)})
            </Text>
          )}
        </Flex>
      </LeftAlign>
    </Flex>
  );
};
