import "@uploadcare/react-uploader/core.css";
import { addDays, fromUnixTime, getUnixTime } from "date-fns";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import { FakeBackLink } from "../../../components/BackLink";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { Button } from "../../../components/CTA";
import { DatePicker } from "../../../components/DatePicker";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H4 } from "../../../components/Heading";
import { AddButton } from "../../../components/icons/AddIcon";
import { BookingsIcon } from "../../../components/icons/BookingsIcon";
import { HorizontalFormatIcon } from "../../../components/icons/briefs/HorizontalFormatIcon";
import { MatchingStatusIcon } from "../../../components/icons/briefs/MatchingStatusIcon";
import { SquareFormatIcon } from "../../../components/icons/briefs/SquareFormatIcon";
import { VerticalFormatIcon } from "../../../components/icons/briefs/VerticalFormatIcon";
import { InstagramIcon } from "../../../components/icons/InstagramIcon";
import { TikTokIcon } from "../../../components/icons/TikTokIcon";
import { TrashIcon } from "../../../components/icons/TrashIcon";
import { Input } from "../../../components/Input";
import Loading from "../../../components/Loading";
import NumberCounter from "../../../components/NumberCounter";
import { Radio } from "../../../components/RadioButton";
import { Underline } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { TextArea } from "../../../components/TextArea";
import { ExternalTextLink, TextLink } from "../../../components/TextLink";
import { Tooltip } from "../../../components/Tooltip";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT, XL_DESKTOP_BREAKPOINT } from "../../../config";
import {
  AspectRatio,
  ContentBriefStatus,
  MediaType,
  useBrandContentBriefQuery,
  useBrandLocationsQuery,
  useCreateContentBriefMutation,
  useGetBrandQuery,
  useUpdateContentBriefMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled, { css, ThemeProp } from "../../../styles";
import { lightTheme } from "../../../styles/theme";
import { getBriefStatusPage } from "../briefs";
import { StyledDateInput } from "../Listing/availability/Event";
import { PlatformButton } from "../Listing/Preview";

const Wrap = styled.div`
  padding: 0 ${(p: ThemeProp) => p.theme.spacing.l};
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: ${(p: ThemeProp) => p.theme.spacing.xl};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p: ThemeProp) => p.theme.spacing.s};
    padding-bottom: ${(p: ThemeProp) => p.theme.spacing.xxxl};
    margin-top: ${(p: ThemeProp) => p.theme.spacing.m};
  }
`;

const Page = styled.div`
  width: 100%;
  position: relative;
`;

export interface MatchParams {
  page: string;
}

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }

  overflow: visible;
`;

interface ContentRequirement {
  id: number;
  mediaType: MediaType;
  aspectRatio: AspectRatio;
  description: string;
  quantity: number;
  referenceLinks: string[];
}

interface FormErrors {
  name: string;
  description: string;
  locations: string;
  contentRequirements: string;
  dueDate: string;
  errorSummary: string;
  errorCount: number;
}

const defaultErrors: FormErrors = {
  name: "",
  description: "",
  locations: "",
  contentRequirements: "",
  dueDate: "",
  errorSummary: "",
  errorCount: 0,
};

export const BriefForm = () => {
  // Form state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const earliestDueDate = useMemo(() => addDays(new Date(), 7), []);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [status, setStatus] = useState<ContentBriefStatus>(
    ContentBriefStatus.Draft
  );
  const client = useGqlClient();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [locations, setLocations] = useState<string[]>([]);
  const createContentBrief = useCreateContentBriefMutation(client);
  const updateContentBrief = useUpdateContentBriefMutation(client);

  // Content requirements state
  const [contentForms, setContentForms] = useState([0]);
  const [contentRequirements, setContentRequirements] = useState<
    ContentRequirement[]
  >([
    {
      id: 0,
      mediaType: MediaType.Video,
      aspectRatio: AspectRatio.Vertical_916,
      description: "",
      quantity: 1,
      referenceLinks: [],
    },
  ]);

  // Add ref for tracking initial values
  const initialValuesRef = useRef<any>(null);

  const { id } = useParams<{ id: string }>();
  const isNew = !id;
  const nameRef = useRef<HTMLInputElement>(null);

  // Add query for fetching existing brief
  const { data: briefData, isLoading: briefLoading } =
    useBrandContentBriefQuery(
      client,
      { id: id ?? "" },
      {
        enabled: !isNew,
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  // Initialize form data from existing brief
  useEffect(() => {
    if (!briefData?.contentBrief || isNew) {
      // Set default values for new brief
      initialValuesRef.current = {
        name: "",
        description: "",
        dueDate: null,
        status: ContentBriefStatus.Draft,
        locations: [],
        contentRequirements: [
          {
            id: 0,
            mediaType: MediaType.Video,
            aspectRatio: AspectRatio.Vertical_916,
            description: "",
            quantity: 1,
            referenceImages: [],
            referenceVideos: [],
          },
        ],
      };
      setHasUnsavedChanges(false);
      return;
    }

    const brief = briefData.contentBrief;

    // Set form values from brief
    setName(brief.name);
    setDescription(brief.description || "");
    setDueDate(brief.dueDate ? fromUnixTime(brief.dueDate) : earliestDueDate);
    setStatus(brief.status);
    setLocations(brief.locations ? brief.locations.map((l) => l.id) : []);

    // Set content requirements
    if (brief.deliverables && brief.deliverables.length > 0) {
      setContentForms(brief.deliverables.map((_, index) => index));
      setContentRequirements(
        brief.deliverables.map((d, index) => ({
          id: index,
          mediaType: d.mediaType as MediaType,
          aspectRatio: d.aspectRatio as AspectRatio,
          description: d.description || "",
          quantity: d.quantity,
          referenceLinks: d.referenceLinks || [],
        }))
      );
    }

    // Store initial values for change detection
    initialValuesRef.current = {
      name: brief.name,
      description: brief.description || "",
      dueDate: brief.dueDate ? fromUnixTime(brief.dueDate) : null,
      status: brief.status,
      // locations: brief.locations?.map((l) => l.id) || [],
      contentRequirements:
        brief.deliverables?.map((d, index) => ({
          id: index,
          mediaType: d.mediaType,
          aspectRatio: d.aspectRatio,
          description: d.description || "",
          quantity: d.quantity,
          referenceLinks: d.referenceLinks || [],
        })) || [],
    };

    setHasUnsavedChanges(false);
  }, [briefData, isNew, earliestDueDate]);

  // Add change detection
  const checkForChanges = useCallback(() => {
    if (!initialValuesRef.current) {
      // New brief - consider any input as a change
      return (
        name.trim() !== "" ||
        description.trim() !== "" ||
        dueDate !== null ||
        locations.length > 0 ||
        contentRequirements.some(
          (cr) => cr.description.trim() !== "" || cr.referenceLinks.length > 0
        )
      );
    }

    // Compare current values with initial values
    const hasChanges =
      name !== initialValuesRef.current.name ||
      description !== initialValuesRef.current.description ||
      !isEqual(dueDate, initialValuesRef.current.dueDate) ||
      !isEqual(locations, initialValuesRef.current.locations) ||
      !isEqual(
        contentRequirements,
        initialValuesRef.current.contentRequirements
      );

    return hasChanges;
  }, [name, description, dueDate, locations, contentRequirements]);

  // Update hasUnsavedChanges when form values change
  useEffect(() => {
    const hasChanges = checkForChanges();
    setHasUnsavedChanges(hasChanges);
  }, [
    name,
    description,
    dueDate,
    locations,
    contentRequirements,
    checkForChanges,
  ]);

  // Add navigation warning for unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const [errors, setErrors] = useState<FormErrors>(defaultErrors);

  const history = useHistory();
  const theme = useTheme();

  // Validation functions
  const validateName = () => {
    let isValid = true;
    let newErrors = { ...defaultErrors };

    const invalidLinks = contentRequirements.filter(
      (req) =>
        req.referenceLinks.filter((e) => !isValidUrl(e.trim())).length > 0
    );

    if (invalidLinks.length > 0) {
      newErrors.contentRequirements = "Invalid reference links";
      newErrors.errorSummary = "Invalid reference links";
      newErrors.errorCount += 1;
      isValid = false;
    }

    if (!name.trim()) {
      newErrors.name = "Name is required";
      newErrors.errorSummary = "Name is required";
      newErrors.errorCount += 1;
      isValid = false;
    }

    return { isValid, newErrors };
  };

  const validatePublish = () => {
    let isValid = true;
    let newErrors = { ...defaultErrors };

    // validate locations
    if (!locations || locations.length === 0) {
      newErrors.locations = "At least one location is required";
      newErrors.errorSummary = "At least one location is required";
      newErrors.errorCount += 1;
      isValid = false;
    }

    if (!dueDate) {
      newErrors.dueDate = "Due date is required";
      newErrors.errorSummary = "Due date is required";
      newErrors.errorCount += 1;
      isValid = false;
    }

    // Validate content requirements
    if (contentRequirements.length === 0) {
      newErrors.contentRequirements = "At least one deliverable is required";
      newErrors.errorSummary = "At least one deliverable is required";
      newErrors.errorCount += 1;
      isValid = false;
    } else {
      const invalidLinks = contentRequirements.filter(
        (req) =>
          req.referenceLinks.filter((e) => !isValidUrl(e.trim())).length > 0
      );

      if (invalidLinks.length > 0) {
        newErrors.contentRequirements =
          "Invalid reference links — please make sure all links point to valid websites";
        newErrors.errorSummary =
          "Invalid reference links — please make sure all links point to valid websites";
        newErrors.errorCount += 1;
        isValid = false;
      }

      // force at least one reference link
      if (contentRequirements.some((req) => req.referenceLinks.length === 0)) {
        newErrors.contentRequirements =
          "At least one reference link is required";
        newErrors.errorSummary = "At least one reference link is required";
        newErrors.errorCount += 1;
        isValid = false;
      }

      // Check if any deliverable is missing a creative direction
      const emptyDescriptions = contentRequirements.filter(
        (req) => !req.description.trim()
      );

      if (emptyDescriptions.length > 0) {
        newErrors.contentRequirements =
          "Creative direction is required for all deliverables";
        newErrors.errorSummary =
          newErrors.errorSummary ||
          "Creative direction is required for all deliverables";
        newErrors.errorCount += 1;
        isValid = false;
      }
    }

    // Validate name
    if (!name.trim()) {
      newErrors.name = "Name is required";
      newErrors.errorSummary = "Name is required";
      newErrors.errorCount += 1;
      isValid = false;
    }

    return { isValid, newErrors };
  };

  const scrollToError = () => {
    if (errors.name) {
      nameRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  // Handle form submission
  const handleSubmit = (submitStatus: ContentBriefStatus) => {
    const { isValid, newErrors } =
      submitStatus === ContentBriefStatus.Draft
        ? validateName()
        : validatePublish();

    if (!isValid) {
      setErrors(newErrors);
      toast.error("Please fix the errors before publishing", {
        description: `${newErrors.errorSummary} ${
          newErrors.errorCount > 1 ? `(+${newErrors.errorCount - 1} more)` : ""
        }`,
        position: "top-right",
        duration: 5000,
      });
      scrollToError();
      return;
    }

    // Set to false initially, will be set to true on failure
    setHasUnsavedChanges(false);

    const formattedDeliverables = contentRequirements.map((requirement) => ({
      mediaType: requirement.mediaType,
      aspectRatio: requirement.aspectRatio,
      description: requirement.description,
      quantity: requirement.quantity,
      referenceLinks: requirement.referenceLinks.filter(
        (link) => link.trim() !== ""
      ),
    }));

    // Clear deliverables if draft has single empty description
    if (
      submitStatus === ContentBriefStatus.Draft &&
      formattedDeliverables.length === 1 &&
      !formattedDeliverables[0].description.trim()
    ) {
      formattedDeliverables.length = 0;
    }

    if (isNew) {
      createContentBrief.mutate(
        {
          brandId: activeBrandId ? activeBrandId : "",
          name: name,
          description: description,
          status: submitStatus,
          deliverables: formattedDeliverables,
          dueDate: dueDate ? getUnixTime(dueDate) : undefined,
          locations: locations,
        },
        {
          onSuccess: (res) => {
            toast.success(
              `${
                submitStatus === ContentBriefStatus.Draft
                  ? "Brief saved to drafts"
                  : "Brief created"
              }`
            );
            setHasUnsavedChanges(false);

            history.push(`/b/briefs/${getBriefStatusPage(submitStatus)}`);
          },
          onError: (error) => {
            setHasUnsavedChanges(true);
            toast.error("Something went wrong");
            console.error("Mutation error:", error);
          },
        }
      );
    } else {
      updateContentBrief.mutate(
        {
          input: {
            id: id,
            name: name,
            description: description,
            status: submitStatus,
            deliverables: formattedDeliverables,
            dueDate: dueDate ? getUnixTime(dueDate) : undefined,
            locations: locations,
          },
        },
        {
          onSuccess: () => {
            toast.success(
              `${
                submitStatus === ContentBriefStatus.Draft
                  ? "Brief saved to drafts"
                  : "Brief updated"
              }`
            );
            setHasUnsavedChanges(false);
            history.push(`/b/briefs/${getBriefStatusPage(submitStatus)}`);
          },
          onError: (error: any) => {
            setHasUnsavedChanges(true);
            toast.error("Something went wrong");
            console.error("Mutation error:", error);
          },
        }
      );
    }
  };

  const addNewContentForm = () => {
    const newId = contentForms.length;
    setContentForms((prev) => [...prev, newId]);
    setContentRequirements((prev) => [
      ...prev,
      {
        id: newId,
        mediaType: MediaType.Video,
        aspectRatio: AspectRatio.Vertical_916,
        description: "",
        quantity: 1,
        referenceLinks: [],
      },
    ]);
  };

  const removeContentForm = (index: number) => {
    setContentForms((prev) => prev.filter((_, i) => i !== index));
    setContentRequirements((prev) => prev.filter((_, i) => i !== index));
  };

  const updateContentRequirement = (
    index: number,
    updates: Partial<ContentRequirement>
  ) => {
    setContentRequirements((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, ...updates };
        }
        return item;
      })
    );
  };

  if (!isNew && briefLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <Wrap>
        <Flex>
          <Flex margin="0 0 m" justify="center">
            <FakeBackLink margin="0" onClick={() => history.push("/b/briefs")}>
              <Chevron direction="left" /> Back to Briefs
            </FakeBackLink>
          </Flex>
        </Flex>

        <View padding="0 0 0">
          <StyledCard>
            <View>
              <H4 margin="0 0 m">Content Brief</H4>
              <Underline selected={true} />
              <View margin="xl 0 xl" style={{ maxWidth: 520 }}>
                <Text weight="bold" margin="0">
                  Name
                </Text>
                <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
                  Max 50 characters
                </Text>
                <Input
                  inputRef={nameRef}
                  inputSize="s"
                  name="name"
                  placeholder="Name your brief..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </View>
              <Flex direction="column" margin="xl 0 0 0">
                <Flex align="center" direction="row">
                  <Text weight="bold" margin="0 s 0 0">
                    Description
                  </Text>
                  <Tooltip>
                    <Text margin="0">
                      Learn about writing effective content briefs{" "}
                      <ExternalTextLink
                        href="https://intercom.help/joli/en/articles/10603071-how-to-write-a-great-content-brief"
                        target="_blank"
                      >
                        here
                      </ExternalTextLink>
                      , or book a call with our creative team{" "}
                      <ExternalTextLink
                        href="https://cal.com/joliapp/call"
                        target="_blank"
                      >
                        here
                      </ExternalTextLink>{" "}
                      🤗
                    </Text>
                  </Tooltip>
                </Flex>
                <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
                  A short statement explaining your brand or campaign objective
                </Text>
                <TextArea
                  name="description"
                  placeholder="Write a short description..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  characterLimit={300}
                  rows={5}
                  characterLength={description.length}
                />
                <LocationsPicker
                  locations={locations}
                  setLocations={setLocations}
                />
                <Text weight="bold" margin="xl s 0 0">
                  Deadline
                </Text>
                <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
                  The final delivery date for the content
                </Text>
                <View margin="0 0 m 0">
                  <DatePicker
                    showTime={false}
                    dateFormat="EEE, do MMMM"
                    placeholderText="Select a date"
                    isClearable={true}
                    startDate={earliestDueDate}
                    date={dueDate}
                    setDate={(date) => setDueDate(date ?? null)}
                    icon={
                      <div style={{ margin: "auto 0" }}>
                        <div
                          style={{
                            marginTop: 3,
                            marginRight: lightTheme.spacing.xl,
                            marginLeft: lightTheme.spacing.s,
                          }}
                        >
                          <BookingsIcon colorPreset="secondary" width={17} />
                        </div>
                      </div>
                    }
                    customInput={
                      <StyledDateInput
                        clearable={false}
                        noDates={false}
                        hasIcon={true}
                      />
                    }
                  />
                </View>
                <AboutBrand />
              </Flex>
            </View>
          </StyledCard>
        </View>

        <View padding="l 0 m">
          <StyledCard>
            <Flex align="flex-start" justify="space-between">
              <View>
                <H4 margin="0 0 m">Deliverables</H4>
                <Underline selected={true} />
              </View>
            </Flex>
            {contentForms.map((formId, index) => (
              <View key={formId} margin={"xl 0 0"}>
                <ContentWrap>
                  <AddContentForm
                    onRemove={() => removeContentForm(index)}
                    showRemove={contentForms.length > 1}
                    contentRequirement={contentRequirements[index]}
                    onChange={(updates) =>
                      updateContentRequirement(index, updates)
                    }
                  />
                </ContentWrap>
              </View>
            ))}
            <View margin="xl 0 0 0">
              <AddButton
                onAdd={addNewContentForm}
                label="another deliverable"
              />
            </View>
          </StyledCard>
        </View>
      </Wrap>
      <Footer>
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <View>
            <RoundButton
              buttonType="secondary"
              onClick={() => handleSubmit(ContentBriefStatus.Draft)}
            >
              {status === ContentBriefStatus.Draft
                ? "Save Draft"
                : "Move to Drafts"}
            </RoundButton>
          </View>
          <div>
            <Flex direction="row" align="center">
              <View>
                {status === ContentBriefStatus.Draft ? (
                  <RoundButton
                    style={{ backgroundColor: theme.color.constructive }}
                    onClick={() => handleSubmit(ContentBriefStatus.Pending)}
                  >
                    <Flex align="center">
                      <Flex
                        style={{
                          marginLeft: -8,
                          marginRight: 8,
                          marginTop: -2,
                        }}
                        align="center"
                        justify="center"
                      >
                        <MatchingStatusIcon height={18} />
                      </Flex>
                      View matches
                    </Flex>
                  </RoundButton>
                ) : (
                  <RoundButton
                    buttonType="secondary"
                    onClick={() => handleSubmit(status)}
                  >
                    Save & Exit
                  </RoundButton>
                )}
              </View>
            </Flex>
          </div>
        </Flex>
      </Footer>
    </Page>
  );
};

const AddContentForm = ({
  onRemove,
  contentRequirement,
  onChange,
  showRemove,
}: {
  onRemove: () => void;
  contentRequirement: ContentRequirement;
  onChange: (updates: Partial<ContentRequirement>) => void;
  showRemove: boolean;
}) => {
  const isVideo = contentRequirement.mediaType === MediaType.Video;

  return (
    <View margin="0 0 0">
      <Flex justify="space-between">
        <Text size="m" weight="bold" margin="0 0 s 0">
          Media Type
        </Text>
        {showRemove && (
          <TrashWrap onClick={onRemove}>
            <TrashIcon height={20} width={20} />
          </TrashWrap>
        )}
      </Flex>
      <Flex align="center" margin="0" style={{ maxWidth: 400 }}>
        <PlatformButton
          title="Video"
          active={isVideo}
          onClick={() => {
            onChange({ mediaType: MediaType.Video });
          }}
        />
        <PlatformButton
          title="Image"
          active={!isVideo}
          onClick={() => {
            onChange({ mediaType: MediaType.Image });
          }}
        />
      </Flex>
      <Text size="m" weight="bold" margin="xl 0 s 0">
        Format
      </Text>
      <ApprovalTypeGrid>
        <ApprovalTypeWrap
          onClick={() => {
            onChange({ aspectRatio: AspectRatio.Vertical_916 });
          }}
          selected={contentRequirement.aspectRatio === AspectRatio.Vertical_916}
        >
          <Flex align="center" margin="0 s 0 0" direction="column">
            <View margin="0 0 0 0">
              <Flex align="center" justify="center" margin="0 0 m 0">
                <VerticalFormatIcon
                  active={
                    contentRequirement.aspectRatio === AspectRatio.Vertical_916
                  }
                />
              </Flex>
              <Text margin="0" weight="bold" align="center">
                9:16
              </Text>
              <Text
                margin="0"
                size="xs"
                colorPreset="secondary"
                align="center"
                isCompact
              >
                Vertical
              </Text>
            </View>
            <Flex align="center" justify="center" margin="m 0 0 0">
              <Radio
                width={10}
                height={10}
                active={
                  contentRequirement.aspectRatio === AspectRatio.Vertical_916
                }
              />
            </Flex>
          </Flex>
        </ApprovalTypeWrap>
        <ApprovalTypeWrap
          onClick={() => {
            onChange({ aspectRatio: AspectRatio.Vertical_45 });
          }}
          selected={contentRequirement.aspectRatio === AspectRatio.Vertical_45}
        >
          <Flex align="center" margin="0 s 0 0" direction="column">
            <View margin="0 0 0 0">
              <Flex align="center" justify="center" margin="0 0 m 0">
                <VerticalFormatIcon
                  active={
                    contentRequirement.aspectRatio === AspectRatio.Vertical_45
                  }
                />
              </Flex>
              <Text margin="0" weight="bold" align="center">
                4:5
              </Text>
              <Text
                margin="0"
                size="xs"
                colorPreset="secondary"
                align="center"
                isCompact
              >
                Vertical
              </Text>
            </View>
            <Flex align="center" justify="center" margin="m 0 0 0">
              <Radio
                width={10}
                height={10}
                active={
                  contentRequirement.aspectRatio === AspectRatio.Vertical_45
                }
              />
            </Flex>
          </Flex>
        </ApprovalTypeWrap>
        <ApprovalTypeWrap
          onClick={() => {
            onChange({ aspectRatio: AspectRatio.Square });
          }}
          selected={contentRequirement.aspectRatio === AspectRatio.Square}
        >
          <Flex align="center" margin="0 s 0 0" direction="column">
            <View margin="0 0 0 0">
              <Flex align="center" justify="center" margin="0 0 m 0">
                <SquareFormatIcon
                  active={contentRequirement.aspectRatio === AspectRatio.Square}
                />
              </Flex>
              <Text margin="0" weight="bold" align="center">
                1:1
              </Text>
              <Text
                margin="0"
                size="xs"
                colorPreset="secondary"
                align="center"
                isCompact
              >
                Square
              </Text>
            </View>
            <Flex align="center" justify="center" margin="m 0 0 0">
              <Radio
                width={10}
                height={10}
                active={contentRequirement.aspectRatio === AspectRatio.Square}
              />
            </Flex>
          </Flex>
        </ApprovalTypeWrap>
        <ApprovalTypeWrap
          onClick={() => {
            onChange({ aspectRatio: AspectRatio.Landscape_169 });
          }}
          selected={
            contentRequirement.aspectRatio === AspectRatio.Landscape_169
          }
        >
          <Flex align="center" margin="0 s 0 0" direction="column">
            <View margin="0 0 0 0">
              <Flex align="center" justify="center" margin="0 0 m 0">
                <HorizontalFormatIcon
                  active={
                    contentRequirement.aspectRatio === AspectRatio.Landscape_169
                  }
                />
              </Flex>
              <Text margin="0" weight="bold" align="center">
                16:9
              </Text>
              <Text
                margin="0"
                size="xs"
                colorPreset="secondary"
                align="center"
                isCompact
              >
                Landscape
              </Text>
            </View>
            <Flex align="center" justify="center" margin="m 0 0 0">
              <Radio
                width={10}
                height={10}
                active={
                  contentRequirement.aspectRatio === AspectRatio.Landscape_169
                }
              />
            </Flex>
          </Flex>
        </ApprovalTypeWrap>
      </ApprovalTypeGrid>
      <Flex direction="column" margin="xl 0 0 0">
        <Flex align="center" direction="row">
          <Text weight="bold" margin="0 s 0 0">
            Creative Direction
          </Text>
          <Tooltip>
            <Text margin="0">
              Learn about writing effective content briefs{" "}
              <ExternalTextLink
                href="https://intercom.help/joli/en/articles/10603071-how-to-write-a-great-content-brief"
                target="_blank"
              >
                here
              </ExternalTextLink>
              , or book a call with our creative team{" "}
              <ExternalTextLink
                href="https://cal.com/joliapp/call"
                target="_blank"
              >
                here
              </ExternalTextLink>{" "}
              🤗
            </Text>
          </Tooltip>
        </Flex>
        <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
          What should and shouldn't be included in the content?
        </Text>
        <TextArea
          name="description"
          placeholder="What needs to be captured..."
          value={contentRequirement.description}
          onChange={(e) => onChange({ description: e.target.value })}
          characterLimit={2000}
          characterLength={contentRequirement.description.length}
          rows={6}
        />
      </Flex>
      <Flex align="center" direction="row" margin="l 0 0">
        <Text weight="bold" margin="0 s 0 0">
          References
        </Text>
      </Flex>
      <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
        Add examples that illustrate the style you're going for (make sure links
        are publicly accessible)
      </Text>

      <References
        references={contentRequirement.referenceLinks}
        setReferences={(references) => onChange({ referenceLinks: references })}
      />

      <Text size="m" weight="bold" margin="xl s 0 0">
        Quantity
      </Text>
      <Flex align="center" direction="row">
        <View margin="s 0 0 0">
          <NumberCounter
            value={contentRequirement.quantity}
            onIncrease={() =>
              onChange({ quantity: contentRequirement.quantity + 1 })
            }
            onDecrease={() =>
              onChange({ quantity: contentRequirement.quantity - 1 })
            }
          />
        </View>
      </Flex>
    </View>
  );
};

const ApprovalTypeWrap = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.l};
  cursor: pointer;
  background-color: ${(p) => p.theme.color.card.background};
  ${(p) =>
    p.selected
      ? css`
          border-color: ${p.theme.color.primary};
          box-shadow: inset 0 0 0 1px ${p.theme.color.primary};
        `
      : null}
`;

const ApprovalTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: ${(p) => p.theme.spacing.l};
  }
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;

const ContentWrap = styled.div`
  background-color: ${(p) => p.theme.color.card.callout};
  padding: ${(p) => p.theme.spacing.l};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardFooter};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xxl};
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xxl};
  }
`;

const RoundButton = styled(Button)`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xl};
  font-size: ${(p) => p.theme.typography.size.s};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.s};
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.l};
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.m};
    padding: ${(p) => p.theme.spacing.m}) ${(p) => p.theme.spacing.xl};
  }
`;

const AboutBrand = () => {
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { data: brand } = useGetBrandQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  if (!brand) {
    return null;
  }

  return (
    <AboutBrandWrap>
      <Flex align="center" direction="row" justify="space-between">
        <Text margin="0 0 0" weight="bold" size="l">
          About {brand?.brand?.name}
        </Text>
        <Tooltip>
          <Text size="s" margin="0">
            Visit your{" "}
            <TextLink to="/b/profile" target="_blank" rel="noopener noreferrer">
              brand profile
            </TextLink>{" "}
            to update this information
          </Text>
        </Tooltip>
      </Flex>
      <Text margin="0 0 m" colorPreset="secondary" size="s">
        {brand.brand?.longDescription || "No description provided"}
      </Text>
      <BrandSocialsGrid>
        {brand.brand?.instagramHandle && (
          <a
            href={`https://www.instagram.com/${brand.brand.instagramHandle}`}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon width={24} />
          </a>
        )}
        {brand.brand?.tikTokHandle && (
          <a
            href={`https://www.tiktok.com/@${brand.brand.tikTokHandle}`}
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon width={24} />
          </a>
        )}
      </BrandSocialsGrid>
    </AboutBrandWrap>
  );
};

const AboutBrandWrap = styled.div`
  background-color: ${(p) => p.theme.color.card.callout};
  padding: ${(p) => p.theme.spacing.l};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  margin-top: ${(p) => p.theme.spacing.l};
`;

const BrandSocialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 24px);
  gap: ${(p) => p.theme.spacing.m};
`;

const References = ({
  references,
  setReferences,
}: {
  references: string[];
  setReferences: (references: string[]) => void;
}) => {
  const addReference = () => {
    setReferences([...references, ""]);
  };

  const removeReference = (index: number) => {
    const updatedReferences = [...references];
    updatedReferences.splice(index, 1);
    setReferences(updatedReferences);
  };

  const updateReference = (index: number, value: string) => {
    const updatedReferences = [...references];
    updatedReferences[index] = value;
    setReferences(updatedReferences);
  };

  return (
    <View>
      {references.map((reference, index) => (
        <Flex direction="row" align="center" margin="0 0 m" key={index}>
          <div style={{ width: "100%" }}>
            <Input
              inputSize="s"
              name="reference"
              value={reference}
              onChange={(e) => updateReference(index, e.target.value)}
              placeholder="Add a link..."
            />
          </div>
          <TrashWrap
            justify="center"
            align="center"
            margin="0 0 0 m"
            onClick={() => removeReference(index)}
          >
            <TrashIcon width={16} />
          </TrashWrap>
        </Flex>
      ))}

      <Flex margin="l 0 0 0">
        <div>
          <AddReferenceButton buttonType="add" size="s" onClick={addReference}>
            + Add Reference
          </AddReferenceButton>
        </div>
      </Flex>
    </View>
  );
};

const AddReferenceButton = styled(Button)`
  filter: brightness(0.92);

  &:hover {
    filter: brightness(0.88);
  }
`;

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

const LocationsPicker = ({
  locations,
  setLocations,
}: {
  locations: string[];
  setLocations: (locations: string[]) => void;
}) => {
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const { data: locationsData, isLoading: locationsLoading } =
    useBrandLocationsQuery(client, {
      brandId: activeBrandId ?? "",
    });

  const allLocations = useMemo(
    () =>
      locationsData?.brand?.locations.locations.map((l) => ({
        label: l.name,
        value: l.id,
      })) || [],
    [locationsData]
  );

  if (locationsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Text weight="bold" margin="xl 0 0">
        Locations
      </Text>
      <Text colorPreset="secondary" size="s" margin="0 0 m" isCompact>
        Where creators will need to shoot the content
      </Text>
      <Dropdown
        width={420}
        showSelectAll
        disableSelection={!allLocations || allLocations.length === 0}
        selectionMode="multiple"
        options={allLocations}
        selectedOptions={locations}
        setSelectedOptions={setLocations}
        renderLabel={() => {
          if (!allLocations || allLocations.length === 0) {
            return "No locations configured";
          }
          if (!locations || locations.length === 0) {
            return "Select a location";
          }

          const firstLocation = allLocations.find(
            (loc) => loc.value === locations[0]
          );

          if (locations.length === 1) {
            return firstLocation?.label || "1 location selected";
          }

          return `${firstLocation?.label} + ${locations.length - 1} more`;
        }}
        placeholder="Select a location"
      />
    </>
  );
};
