import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingPostSubmissionsQuery,
  GetBookingQuery,
} from "../../../graphql/generated";
import styled, { css } from "../../../styles";
import { Card } from "../../Card";
import { CloseIcon } from "../../CloseIcon";
import { Flex } from "../../Flex";
import VideoReview from "../../VideoReview";

interface Props {
  bookingPostSubmissions: BookingPostSubmissionsQuery["bookingPosts"]["bookingPosts"];
  creator: GetBookingQuery["booking"]["creator"];
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  activeSubmission?: BookingPostSubmissionsQuery["bookingPosts"]["bookingPosts"][0];
  onReview: () => void;
}

export const ApproveContentOverlay = (props: Props) => {
  const [activeSubmission, setActiveSubmission] = useState(
    () => props.activeSubmission ?? props.bookingPostSubmissions[0]
  );

  const handleSubmissionClick = (
    submission: BookingPostSubmissionsQuery["bookingPosts"]["bookingPosts"][0]
  ) => {
    setActiveSubmission(submission);
  };

  useEffect(() => {
    setActiveSubmission(
      props.activeSubmission ?? props.bookingPostSubmissions[0]
    );
  }, [props.activeSubmission, props.bookingPostSubmissions]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen && props.setIsOpen(false);
      }}
    >
      <Backdrop aria-hidden="true">
        <StyledDialogPanel>
          {props.bookingPostSubmissions.length > 1 && (
            <Flex
              direction="row"
              align="center"
              justify="center"
              margin="xl 0 0"
            >
              <ImageGrid>
                {props.bookingPostSubmissions.map((submission) => {
                  return (
                    <div
                      key={submission.id}
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => {
                        handleSubmissionClick(submission);
                      }}
                    >
                      <Image
                        key={submission.id}
                        src={
                          submission.thumbnailUrl &&
                          submission.thumbnailUrl != ""
                            ? submission.thumbnailUrl
                            : submission.mediaUrl
                        }
                        square={true}
                      />
                    </div>
                  );
                })}
              </ImageGrid>
            </Flex>
          )}
          <CenteredContainer maxWidth={600} noPadding={true}>
            <AbsoluteWrap onClick={() => props.setIsOpen(false)}>
              <CloseIcon width={28} height={28} />
            </AbsoluteWrap>

            <VideoReview
              submission={activeSubmission}
              creator={props.creator}
              onReview={props.onReview}
            />
          </CenteredContainer>
        </StyledDialogPanel>
      </Backdrop>
    </Dialog>
  );
};

const ImageGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.m};
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  margin-right: ${(p) => p.theme.spacing.m};
`;

const Image = styled.img<{ square?: boolean }>`
  width: 62px;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  aspect-ratio: 1 / 1;
  margin: 0 ${(p) => p.theme.spacing.s};
`;

const StyledDialogPanel = styled(Dialog.Panel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 95%;
`;

const CenteredContainer = styled(Card)<{
  maxWidth?: number;
  noPadding?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  outline: none !important;
  max-height: 90vh;
  width: 100%;
  box-shadow: none !important;

  [data-headlessui-state="open"] *:focus {
    outline: none !important;
  }

  ${(p) =>
    p.noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: ${(p) => p.theme.spacing.xl};

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            padding: ${(p) => p.theme.spacing.l};
          }
        `}
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  z-index: 1;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.text};

      &:hover {
        fill: ${(p) => p.theme.color.typography.heading};
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${(p) => p.theme.spacing.m};
`;
