import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, CTA } from "../../components/CTA";
import { Callout } from "../../components/Callout";
import { Card } from "../../components/Card";
import { CloseIcon } from "../../components/CloseIcon";
import { CardDivider } from "../../components/Divider";
import { Dropdown, DropdownOption } from "../../components/Dropdown";
import { Flex } from "../../components/Flex";
import { H1, H2, H3 } from "../../components/Heading";
import { DoubleInputGroup, Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { ShareLink } from "../../components/ShareLink";
import { Switch } from "../../components/Switch";
import { Tag } from "../../components/Tag";
import { Text } from "../../components/Text";
import { Tooltip } from "../../components/Tooltip";
import { View } from "../../components/View";
import { CrownIcon } from "../../components/icons/CrownIcon";
import { TickIcon } from "../../components/icons/TickIcon";
import config, { MOBILE_BREAKPOINT } from "../../config";
import {
  BillingPeriod,
  BillingPlanType,
  useBillingQuery,
  useCreateStripeCheckoutMutation,
  useCreateStripePortalMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import { authSelectors } from "../../store/auth/selector";
import styled from "../../styles";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }

  margin-bottom: ${(p) => p.theme.spacing.xxl};
`;

const StyledCard = styled(Card)`
  overflow: visible;
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

export const ChecklistWrap = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing.l};
  grid-column-start: 2;
  grid-column-end: 4;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-column-start: 2;
    grid-column-end: 4;
  }
`;

const InputWrap = styled(View)`
  grid-column-start: 2;
  grid-column-end: 4;
`;

const PlanWrap = styled(View)`
  display: grid;
  cursor: pointer;
  user-select: none;
  grid-template-columns: 48px auto auto;
`;

const StyledDoubleInputGroup = styled(DoubleInputGroup)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export function Checklist({ header }: { header: string }) {
  return (
    <Flex align="center" direction="row">
      <div style={{ marginBottom: -4 }}>
        <TickIcon width={20} />
      </div>
      <Text size="s" margin="0 0 0 xs">
        {header}
      </Text>
    </Flex>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1020px;
  text-align: left;
  padding: 0 ${(p) => p.theme.spacing.xl};
  padding-bottom: ${(p) => p.theme.spacing.xxxl};
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

export function Billing() {
  const client = useGqlClient();
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const createStripePortal = useCreateStripePortalMutation(client);
  const createStripeCheckout = useCreateStripeCheckoutMutation(client);
  const [selectedPeriod, setSelectedPeriod] = useState<BillingPeriod>(
    BillingPeriod.BillingPeriodMonth
  );
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const [enableJoliExperts, setEnableJoliExperts] = useState<boolean>(false);
  const [enableBrandsAndRegions, setEnableBrandsAndRegions] =
    useState<boolean>(false);
  const [brandsCount, setBrandsCount] = useState<number>(1);
  const [enableIntegrations, setEnableIntegrations] = useState<boolean>(false);
  const availableIntegrations: DropdownOption[] = [
    { label: "Access Collins", value: "Access Collins", comingSoon: false },
    { label: "Toggle", value: "Toggle", comingSoon: false },
    { label: "TipJar", value: "TipJar", comingSoon: false },
    { label: "Google Reviews", value: "Google Reviews", comingSoon: false },
    { label: "OpenTable", value: "OpenTable", comingSoon: true },
  ];
  const [selectedIntegrations, setSelectedIntegrations] = useState<string[]>(
    []
  );
  const availableRegions: DropdownOption[] = [
    { label: "North West", value: "North West", comingSoon: false },
    { label: "North East", value: "North East", comingSoon: false },
    {
      label: "Yorkshire & Humber",
      value: "Yorkshire & Humber",
      comingSoon: false,
    },
    { label: "West Midlands", value: "West Midlands", comingSoon: false },
    { label: "East Midlands", value: "East Midlands", comingSoon: false },
    { label: "East Anglia", value: "East Anglia", comingSoon: false },
    { label: "South West", value: "South West", comingSoon: false },
    { label: "South East", value: "South East", comingSoon: false },
    { label: "Greater London", value: "Greater London", comingSoon: false },
    { label: "Cymru Wales", value: "Cymru Wales", comingSoon: false },
    { label: "Scotland", value: "Scotland", comingSoon: false },
  ];
  const [selectedRegions, setSelectedRegions] = useState<string[]>(["london"]);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountText, setDiscountText] = useState<string>("");
  const [dueToday, setDueToday] = useState<number>(0);
  const [brandsAndRegions, setBrandsAndRegions] = useState<number>(0);
  const [annualSaving, setAnnualSaving] = useState<number>(0);
  const planPrice = 299;
  const brandAndRegionPrice = 99;
  const integrationPrice = 49;
  const joliExpertsPrice = 799;
  const [paymentLink, setPaymentLink] = useState("");

  // check if success=true is in the url
  const urlParams = new URLSearchParams(window.location.search);
  const success = urlParams.get("success");
  const sessionID = urlParams.get("session_id");

  const { data, isLoading, error } = useBillingQuery(
    client,
    {
      brandID: activeBrandId ? activeBrandId : "",
    },
    {
      enabled: true,
    }
  );

  useEffect(() => {
    if (enableBrandsAndRegions) {
      return;
    }
    setBrandsCount(1);
    setSelectedRegions(["Greater London"]);
  }, [enableBrandsAndRegions]);

  useEffect(() => {
    if (enableIntegrations) {
      return;
    }
    setSelectedIntegrations([]);
  }, [enableIntegrations]);

  useEffect(() => {
    const calculateAddOnPrice = () => {
      let price = 299;
      if (enableJoliExperts) price += joliExpertsPrice;
      price += selectedIntegrations.length * integrationPrice;
      const brands = brandsCount > 0 ? brandsCount - 1 : 0;
      const regions =
        selectedRegions.length > 0 ? selectedRegions.length - 1 : 0;
      setBrandsAndRegions(brands + regions);
      setAnnualSaving(price * 12 - price * 10);

      const totalBeforeDiscount =
        selectedPeriod === BillingPeriod.BillingPeriodAnnual
          ? Math.round(price * 10)
          : price;

      // Get referral ID from the data object
      let referralId = null;
      if (data && data.account && data.account.referredById) {
        referralId = data.account.referredById;
      } else {
        // Fallback to localStorage if not available in data
        referralId =
          localStorage.getItem(config.referrals.localStorageKey) ||
          (document.cookie.match(/joli_referral_id=([^;]+)/) || [])[1] ||
          null;
      }

      let discount = 0;
      let discountText = "";
      if (referralId) {
        const discountPercentage =
          referralId === config.referrals.independent.id
            ? config.referrals.independent.discount
            : config.referrals.friend.discount;

        discount = Math.round((totalBeforeDiscount * discountPercentage) / 100);

        discountText =
          referralId === config.referrals.independent.id
            ? `Independent discount (${discountPercentage}% off)`
            : `Friend of @${referralId} (${discountPercentage}% off)`;
      }

      setDiscountText(discountText);
      setDiscountAmount(discount);
      setDueToday(totalBeforeDiscount - discount);
    };

    calculateAddOnPrice();
  }, [
    enableJoliExperts,
    selectedIntegrations,
    brandsCount,
    selectedRegions.length,
    selectedPeriod,
    enableIntegrations,
    data,
  ]);

  useEffect(() => {
    if (
      data &&
      data.brand &&
      data.brand.stripeCustomerId &&
      !success &&
      !sessionID
    ) {
      createStripePortal.mutate(
        {},
        {
          onSuccess: (res) => {
            window.location.href = res.createStripePortal.redirectUrl;
          },
        }
      );
    }
  }, [data]);

  if (
    isLoading ||
    createStripeCheckout.isLoading ||
    createStripePortal.isLoading
  ) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <Wrap>
        <H1 margin="0 0 xl">Billing</H1>
        <Text>Something went wrong</Text>
      </Wrap>
    );
  }

  if (success && sessionID) {
    return (
      <Modal isOpen={true} onClose={() => {}} maxWidth={420}>
        <Flex direction="column">
          <H2 margin="0 0 l">Your subscription is active ✨</H2>
          <Text margin="0 0 l">
            You'll now have access to your own billing portal within Joli to
            review your subscription details, update payment information, and
            view invoices.
          </Text>
          <Text margin="0 0 l">
            Should you need any assistance with your billing going forwards,
            please reach out to our accounts team on accounts@joliapp.com.
          </Text>
          <CTA margin="0" to="">
            Got it!
          </CTA>
        </Flex>
      </Modal>
    );
  }

  if (data.brand?.stripeCustomerId && !success && !sessionID) {
    createStripePortal.mutate(
      {},
      {
        onSuccess: (res: any) => {
          window.open(res.createStripePortal.redirectUrl, "_blank");
        },
      }
    );
  }

  const annualPlan = data.billingPlans.filter(
    (e) =>
      e.period === BillingPeriod.BillingPeriodAnnual &&
      e.planType === BillingPlanType.BillingPlanTypeEssential
  )[0];

  const monthlyPlan = data.billingPlans.filter(
    (e) =>
      e.period === BillingPeriod.BillingPeriodMonth &&
      e.planType === BillingPlanType.BillingPlanTypeEssential
  )[0];

  return (
    <Wrap>
      <Modal isOpen={paymentLink !== ""} onClose={() => setPaymentLink("")}>
        <H3 margin="0 0 0">Share payment link</H3>
        <Text margin="l 0">
          Share the link with someone else on your team to complete payment
        </Text>
        <ShareLink link={paymentLink} />
        <Text margin="l 0 0" colorPreset="warning">
          This link will expire in 24 hours. After that, you'll need to create a
          new one
        </Text>
        <Button size="s" onClick={() => setPaymentLink("")} margin="xl 0 0 0">
          Done
        </Button>
      </Modal>
      <H2 margin="0 0 l">Choose Plan</H2>
      <Grid>
        <StyledCard padding="xl xl xl" margin="0 0 xl">
          <Flex justify="space-between">
            <View padding="0 xs">
              <Text
                colorPreset="secondaryHeading"
                weight="bold"
                size="l"
                margin="0 s 0 0"
                isCompact
              >
                Essentials Plan
              </Text>
              <Text colorPreset="secondary" margin="0 0 0">
                Everything you need to get started with Joli
              </Text>
              <Text
                size="s"
                colorPreset="secondaryHeading"
                weight="semi"
                margin="m 0 xs"
              >
                What's included:
              </Text>
              <ChecklistWrap margin="0">
                <Checklist header="Access vetted creators" />
                <Checklist header="Content gallery" />
                <Checklist header="Unlimited campaigns & events" />
                <Checklist header="Team management" />
                <Checklist header="Advanced reporting" />
                <Checklist header="Live chat & email support" />
              </ChecklistWrap>
            </View>
            <View margin="0 0 0 xxl" style={{ textAlign: "right" }}>
              <Text size="xxs" colorPreset="secondary" margin="0" isCompact>
                FROM
              </Text>
              <Text weight="bold" size="xxl" margin="0" isInline isCompact>
                £249
                <Text
                  size="s"
                  margin="0"
                  weight="normal"
                  colorPreset="secondary"
                  isInline
                >
                  /mo
                </Text>
              </Text>
            </View>
          </Flex>

          <CardDivider margin="xl 0" />
          <PlanWrap
            onClick={() => setEnableBrandsAndRegions(!enableBrandsAndRegions)}
          >
            <Switch
              size="s"
              value={enableBrandsAndRegions}
              onChange={() => console.log("switched")}
            />
            <View>
              <Flex align="center" direction="row">
                <Text
                  colorPreset="secondaryHeading"
                  weight="bold"
                  size="l"
                  margin="0 s 0 0"
                  isCompact
                >
                  Additional Brands and Regions
                </Text>
              </Flex>
              <Flex align="center" direction="row">
                <Text colorPreset="secondary" margin="0 xs 0 0">
                  Multiple brand and region support
                </Text>
                <div className="tooltip">
                  <Tooltip>
                    <Text size="s" margin="0">
                      Add additional brands to manage multiple social media
                      accounts, and expand your coverage by including additional
                      UK regions
                    </Text>
                  </Tooltip>
                </div>
              </Flex>
            </View>
            <View margin="0 0 0 xxl" style={{ textAlign: "right" }}>
              <Text size="xxs" colorPreset="secondary" margin="0" isCompact>
                FROM
              </Text>
              <Text weight="bold" size="xxl" margin="0" isInline isCompact>
                £83
                <Text
                  size="s"
                  margin="0"
                  weight="normal"
                  colorPreset="secondary"
                  isInline
                >
                  /mo
                </Text>
              </Text>
            </View>

            <InputWrap
              onClick={(e: any) =>
                enableBrandsAndRegions && e.stopPropagation()
              }
            >
              <View margin="l 0 0">
                <StyledDoubleInputGroup>
                  <div style={{ position: "relative" }}>
                    <InputAbsoluteWrap>
                      <Text
                        margin="0"
                        size="xs"
                        weight="semi"
                        colorPreset="secondaryHeading"
                      >
                        Brands
                      </Text>
                    </InputAbsoluteWrap>
                    <Input
                      inputSize="s"
                      name="brands"
                      type="number"
                      value={brandsCount}
                      onChange={(e) => setBrandsCount(Number(e.target.value))}
                      margin="0 0 0 0"
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <InputAbsoluteWrap>
                      <Text
                        margin="0"
                        size="xs"
                        weight="semi"
                        colorPreset="secondaryHeading"
                      >
                        Regions
                      </Text>
                    </InputAbsoluteWrap>
                    <Dropdown
                      size="s"
                      options={availableRegions}
                      selectedOptions={selectedRegions}
                      setSelectedOptions={setSelectedRegions}
                      renderLabel={() => {
                        return selectedRegions.length === 0
                          ? "Select a region"
                          : selectedRegions.length === 1
                          ? `${selectedRegions.length} region selected`
                          : `${selectedRegions.length} regions selected`;
                      }}
                      placeholder="Select regions..."
                      disableSelection={!enableBrandsAndRegions}
                      selectionMode="multiple"
                    />
                  </div>
                </StyledDoubleInputGroup>
              </View>
            </InputWrap>
          </PlanWrap>
          <Callout
            type="info"
            text="One brand and one region are included as standard"
          />
          <CardDivider margin="xl 0" />
          <PlanWrap onClick={() => setEnableIntegrations(!enableIntegrations)}>
            <Switch
              size="s"
              value={enableIntegrations}
              onChange={() => console.log("switched")}
            />
            <View>
              <Flex align="center" direction="row">
                <Text
                  colorPreset="secondaryHeading"
                  weight="bold"
                  size="l"
                  margin="0 s 0 0"
                  isCompact
                >
                  Partner Integrations
                </Text>
              </Flex>
              <Text colorPreset="secondary" margin="0 0 m">
                Connect booking and payment systems
              </Text>
              <View style={{ position: "relative" }}>
                <Dropdown
                  margin="m"
                  options={availableIntegrations}
                  selectedOptions={selectedIntegrations}
                  setSelectedOptions={setSelectedIntegrations}
                  renderLabel={() => {
                    return selectedIntegrations.length === 0
                      ? "Select an integration"
                      : selectedIntegrations.length === 1
                      ? `${selectedIntegrations.length} integration selected`
                      : `${selectedIntegrations.length} integrations selected`;
                  }}
                  placeholder="Select integrations..."
                  disableSelection={!enableIntegrations}
                  selectionMode="multiple"
                  size="s"
                />
              </View>
            </View>
            <View margin="0 0 0 xxl" style={{ textAlign: "right" }}>
              <Text size="xxs" colorPreset="secondary" margin="0" isCompact>
                FROM
              </Text>
              <Text weight="bold" size="xxl" margin="0" isInline isCompact>
                £41
                <Text
                  size="s"
                  margin="0"
                  weight="normal"
                  colorPreset="secondary"
                  isInline
                >
                  /mo
                </Text>
              </Text>
            </View>
          </PlanWrap>
          <CardDivider margin="xl 0" />
          <PlanWrap onClick={() => setEnableJoliExperts(!enableJoliExperts)}>
            <Switch
              size="s"
              value={enableJoliExperts}
              onChange={() => console.log("switched")}
            />
            <View>
              <Flex align="center" direction="row">
                <View margin="0 s 0 0">
                  <CrownIcon />
                </View>
                <Text
                  colorPreset="secondaryHeading"
                  weight="bold"
                  size="l"
                  margin="0 s 0 0"
                  isCompact
                >
                  Joli Experts
                </Text>
              </Flex>
              <Text colorPreset="secondary" margin="0 0 0">
                Bespoke campaign management and strategic support
              </Text>
              <Text
                size="s"
                colorPreset="secondaryHeading"
                weight="semi"
                margin="m 0 xs"
              >
                What's included:
              </Text>
              <Checklist header="Dedicated Social Media strategist" />
              <Checklist header="Premium creator collaborations" />
              <Checklist header="Guaranteed reach and performance" />
            </View>
            <View margin="0 0 0 xxl" style={{ textAlign: "right" }}>
              <Text size="xxs" colorPreset="secondary" margin="0" isCompact>
                FROM
              </Text>
              <Text weight="bold" size="xxl" margin="0" isInline isCompact>
                £666
                <Text
                  size="s"
                  margin="0"
                  weight="normal"
                  colorPreset="secondary"
                  isInline
                >
                  /mo
                </Text>
              </Text>
            </View>
          </PlanWrap>
        </StyledCard>
        <RightHandGrid direction="column" style={{ gap: theme.spacing.l }}>
          <Card padding="0 m">
            <Flex align="center" justify="center" direction="column">
              <Flex align="center">
                <Text>Pay monthly</Text>{" "}
                <View margin="0 l">
                  <Switch
                    size="s"
                    value={selectedPeriod === BillingPeriod.BillingPeriodAnnual}
                    onChange={() => {
                      const newStatus =
                        selectedPeriod === BillingPeriod.BillingPeriodAnnual
                          ? BillingPeriod.BillingPeriodMonth
                          : BillingPeriod.BillingPeriodAnnual;
                      setSelectedPeriod(newStatus);
                    }}
                  />
                </View>
                <Text>Pay annually</Text>
              </Flex>
            </Flex>
          </Card>
          <Card padding="l">
            <H3 style={{ textAlign: "center" }} margin="xs 0 0 0">
              Review Changes
            </H3>
            <Flex justify="center" margin="s 0 xl">
              <Tag
                shadow={false}
                padding="l"
                text={`Save £${annualSaving.toLocaleString()} by paying annually`}
                color="green"
              />
            </Flex>
            <Flex justify="space-between" direction="row" margin="l 0 m">
              <Text colorPreset="secondary" weight="semi" margin="0" size="s">
                Essentials Plan
              </Text>
              <Text margin="0">
                {`£${
                  selectedPeriod === BillingPeriod.BillingPeriodAnnual
                    ? Math.round((planPrice * 10) / 12)
                    : planPrice
                }/mo`}
              </Text>
            </Flex>
            {brandsAndRegions > 0 ? (
              <>
                <CardDivider margin="s 0" />
                <Flex justify="space-between" direction="row" margin="l 0 m">
                  <Text
                    colorPreset="secondary"
                    weight="semi"
                    margin="0"
                    size="s"
                  >
                    Brands & Regions
                  </Text>
                  <Text margin="0">
                    {`£${
                      selectedPeriod === BillingPeriod.BillingPeriodAnnual
                        ? Math.round(
                            (brandsAndRegions * brandAndRegionPrice * 10) / 12
                          )
                        : brandsAndRegions * brandAndRegionPrice
                    }/mo`}
                  </Text>
                </Flex>
              </>
            ) : null}
            {selectedIntegrations.length > 0 ? (
              <>
                <CardDivider margin="s 0" />
                <Flex justify="space-between" direction="row" margin="l 0 m">
                  <Text
                    colorPreset="secondary"
                    weight="semi"
                    margin="0"
                    size="s"
                  >
                    Integrations
                  </Text>
                  <Text margin="0">
                    {`£${
                      selectedPeriod === BillingPeriod.BillingPeriodAnnual
                        ? Math.round(
                            (selectedIntegrations.length *
                              integrationPrice *
                              10) /
                              12
                          )
                        : selectedIntegrations.length * integrationPrice
                    }/mo`}
                  </Text>
                </Flex>
              </>
            ) : null}
            {enableJoliExperts ? (
              <>
                <CardDivider margin="s 0" />
                <Flex justify="space-between" direction="row" margin="l 0 m">
                  <Text
                    colorPreset="secondary"
                    weight="semi"
                    margin="0"
                    size="s"
                  >
                    Joli Experts
                  </Text>
                  <Text margin="0">
                    {`£${
                      selectedPeriod === BillingPeriod.BillingPeriodAnnual
                        ? Math.round((joliExpertsPrice * 10) / 12)
                        : joliExpertsPrice
                    }/mo`}
                  </Text>
                </Flex>
              </>
            ) : null}
            <CardDivider margin="s 0" />
            <Flex justify="space-between" direction="row" margin="l 0 m">
              <Text colorPreset="secondary" weight="semi" margin="0" size="s">
                Billed
              </Text>
              <Text margin="0">
                {selectedPeriod === BillingPeriod.BillingPeriodAnnual
                  ? "Annually"
                  : "Monthly"}
              </Text>
            </Flex>
            <CardDivider margin="s 0" />
            {discountAmount > 0 ? (
              <div>
                <Flex justify="space-between" direction="row" margin="l 0 0">
                  <View>
                    <Text
                      colorPreset="secondary"
                      weight="semi"
                      margin="0"
                      size="s"
                    >
                      Discount
                    </Text>
                  </View>
                  <View>
                    <Text
                      color={theme.color.constructive}
                      margin="0"
                      weight="bold"
                      size="l"
                    >
                      £{discountAmount}
                    </Text>
                  </View>
                </Flex>
                {discountText && (
                  <Flex
                    align="center"
                    margin="0 0 0 0"
                    onClick={() => setDiscountAmount(0)}
                  >
                    <View margin="0 xs 0 0">
                      <div
                        style={{
                          marginBottom: -2,
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon
                          colorPreset="secondary"
                          width={12}
                          height={12}
                        />
                      </div>
                    </View>
                    <Text
                      colorPreset="secondary"
                      margin="0 0 0"
                      size="xs"
                      isCompact
                    >
                      {discountText}
                    </Text>{" "}
                  </Flex>
                )}
                <View margin="m 0 0 0">
                  <CardDivider margin="s 0" />
                </View>
              </div>
            ) : null}
            <Flex justify="space-between" direction="row" margin="l 0 m">
              <Text
                size="xl"
                colorPreset="secondaryHeading"
                weight="bold"
                margin="0"
              >
                Due today
              </Text>
              <Text
                size="xl"
                margin="0"
                colorPreset="secondaryHeading"
                weight="bold"
              >
                £{dueToday.toLocaleString("en-GB")}
              </Text>
            </Flex>
            <Button
              onClick={() => {
                if (createStripeCheckout.isLoading) {
                  return;
                }

                createStripeCheckout.mutate(
                  {
                    managedPlan: enableJoliExperts,
                    additionalBrands: brandsCount - 1,
                    additionalRegions: selectedRegions.length - 1,
                    integrations: selectedIntegrations.length,
                    allowPromotionCodes: discountAmount === 0,
                    billingPlanId:
                      selectedPeriod === BillingPeriod.BillingPeriodAnnual
                        ? annualPlan.id
                        : monthlyPlan.id,
                  },
                  {
                    onSuccess: (res) => {
                      window.location.href =
                        res.createStripeCheckout.redirectUrl;
                    },
                  }
                );
              }}
            >
              {createStripeCheckout.isLoading || createStripePortal.isLoading
                ? "Loading..."
                : "Confirm Plan"}
            </Button>
            <Text
              colorPreset="secondary"
              size="xs"
              align="center"
              margin="m 0 0"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (createStripeCheckout.isLoading) {
                  return;
                }

                createStripeCheckout.mutate(
                  {
                    managedPlan: enableJoliExperts,
                    additionalBrands: brandsCount - 1,
                    additionalRegions: selectedRegions.length - 1,
                    integrations: selectedIntegrations.length,
                    allowPromotionCodes: discountAmount === 0,
                    billingPlanId:
                      selectedPeriod === BillingPeriod.BillingPeriodAnnual
                        ? annualPlan.id
                        : monthlyPlan.id,
                  },
                  {
                    onSuccess: (res) => {
                      setPaymentLink(res.createStripeCheckout.redirectUrl);
                    },
                  }
                );
              }}
            >
              Or share the payment link with a colleague
            </Text>
          </Card>
        </RightHandGrid>
      </Grid>
    </Wrap>
  );
}

const RightHandGrid = styled(Flex)`
  position: sticky;
  top: 0;
  align-self: start;
`;

const InputAbsoluteWrap = styled.div`
  position: absolute;
  top: -8px;
  left: 8px;
  padding: 0px 4px;
  background: ${(p) => p.theme.color.card.background};
  z-index: 1;
`;
