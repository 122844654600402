import { format, fromUnixTime, getUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BookingPost } from "../../components/BookingPost";
import {
  FilterOption,
  ReportFilter,
  content,
} from "../../components/Brand/ReportFilter";
import { CTA } from "../../components/CTA";
import { DateRangePicker } from "../../components/DateRangePicker";
import { Dropdown } from "../../components/Dropdown";
import { Flex } from "../../components/Flex";
import { H1, H3 } from "../../components/Heading";
import { FakeInput } from "../../components/Input";
import Loading from "../../components/Loading";
import { NewButton } from "../../components/NewButton";
import { StatsCard } from "../../components/StatsCard";
import { Text } from "../../components/Text";
import { TextLink } from "../../components/TextLink";
import { UpgradePlan } from "../../components/UpgradePlan";
import { View } from "../../components/View";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import {
  LARGE_DESKTOP_BREAKPOINT,
  MEDIUM_DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from "../../config";
import {
  BillingPlanType,
  BookingPostMediaType,
  BookingPostType,
  BookingPostsSortBy,
  ReportsQuery,
  useBrandListingsQuery,
  useBrandLocationsQuery,
  useReportsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useWindowSize } from "../../hooks/useWindowSize";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";
import {
  exampleReportData,
  exampleReportPosts,
} from "../../utils/exampleReportData";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: ${MEDIUM_DESKTOP_BREAKPOINT}px;
  padding: 0 ${(p) => p.theme.spacing.xxl} ${(p) => p.theme.spacing.xl};
  box-sizing: border-box;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.l};
  row-gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.l};
  row-gap: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${(p) => p.theme.spacing.l};
  row-gap: ${(p) => p.theme.spacing.xl};
  margin-bottom: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: ${(p) => p.theme.spacing.s};
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.m};
  }

  @media (min-width: ${SMALL_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.l};
  }

  @media (min-width: ${MEDIUM_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.l};
  }

  @media (min-width: ${LARGE_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: ${(p) => p.theme.spacing.l};
  }
`;

const HeaderWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${(p) => p.theme.spacing.xl};
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${(p) => p.theme.spacing.m};
    margin-bottom: ${(p) => p.theme.spacing.l};
  }
`;

const sortOptions = [
  {
    label: "Views",
    value: BookingPostsSortBy.BookingPostsSortByImpressions,
  },
  {
    label: "Reach",
    value: BookingPostsSortBy.BookingPostsSortByReach,
  },
  {
    label: "Engagement",
    value: BookingPostsSortBy.BookingPostsSortByEngagement,
  },
];

export interface MatchParams {
  page: string;
}

export const BrandReports = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [csvUrl, setCsvUrl] = useState<string | null>(null);
  const window = useWindowSize();
  const brand = useSelector(authSelectors.activeBrand);
  const minDate = brand?.createdAt
    ? fromUnixTime(brand.createdAt)
    : new Date(0);
  const handleDateChange = ([newStartDate, newEndDate]: [
    Date | null,
    Date | null
  ]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const [listings, setListings] = useState<FilterOption[]>([]);
  const [locations, setLocations] = useState<FilterOption[]>([]);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedContentType, setSelectedContentType] = useState<
    BookingPostType[]
  >([]);

  const client = useGqlClient();
  const { data: listingsData, isLoading: listingLoading } =
    useBrandListingsQuery(
      client,
      { brandId: brand?.id },
      {
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const { data: locationsData, isLoading: locationsLoading } =
    useBrandLocationsQuery(
      client,
      { brandId: brand && brand.id ? brand.id : "" },
      {
        enabled: brand?.id !== null,
        refetchOnWindowFocus: false,
      }
    );

  const transformDataToFilterOptions = (data: any[], key: string) =>
    data.map((item) => ({ id: item.id, label: item[key] }));

  useEffect(() => {
    if (listingsData) {
      const transformedListings = transformDataToFilterOptions(
        listingsData.listings.listings,
        "name"
      );
      setListings(transformedListings);
      setSelectedListings(transformedListings.map((listing) => listing.id));
    }
    if (locationsData && locationsData.brand) {
      const transformedLocations = transformDataToFilterOptions(
        locationsData.brand.locations.locations,
        "name"
      );
      setLocations(transformedLocations);
      setSelectedLocations(transformedLocations.map((location) => location.id));
    }
    setSelectedContentType(content.map((c) => c.id as BookingPostType));
  }, [listingsData, locationsData]);

  if (!listingsData || !locationsData || locationsLoading || listingLoading) {
    return <Loading />;
  }

  return (
    <Wrap>
      <HeaderWrap>
        <H1 margin="0">Reports</H1>
        <Flex align="center">
          <DateRangePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={handleDateChange}
            tooltip="Based on the application date"
          />
          <ReportFilter
            selectedContentType={selectedContentType}
            setSelectedContentType={setSelectedContentType}
            listings={listings}
            selectedListings={selectedListings}
            setSelectedListings={setSelectedListings}
            locations={locations}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
          {csvUrl &&
          window &&
          window.width &&
          window.width > MOBILE_BREAKPOINT ? (
            <View margin="0 0 0 s">
              <a
                href={csvUrl}
                download={`joli-csv-export-${format(
                  startDate ? startDate : minDate,
                  "dd/MM/yyy"
                )}-to-${format(endDate ? endDate : new Date(), "dd/MM/yyy")}`}
              >
                <FakeInput>
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: 38, width: 38 }}
                  >
                    <DownloadIcon
                      colorPreset="secondary"
                      width={15}
                      height={16}
                    />
                  </Flex>
                </FakeInput>
              </a>
            </View>
          ) : null}
        </Flex>
      </HeaderWrap>

      <Reports
        startDate={startDate ? startDate : minDate}
        endDate={endDate}
        setCsvUrl={setCsvUrl}
        selectedListings={selectedListings}
        selectedLocations={selectedLocations}
        selectedContentType={selectedContentType}
      />
    </Wrap>
  );
};

interface ReportsProps {
  startDate: Date | null;
  endDate: Date | null;
  setCsvUrl: (url: string | null) => void;
  selectedListings: string[];
  selectedLocations: string[];
  selectedContentType: BookingPostType[];
}

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return date.toISOString().split("T")[0];
};

const convertArrayToCSV = (
  array: ReportsQuery["reportMetrics"]["metrics"]
): string => {
  const modifiedData = array.map(({ timestamp, ...rest }) => ({
    ...rest,
    date: formatDate(timestamp),
  }));

  const headers = Object.keys(modifiedData[0]).join(",");

  const rows = modifiedData.map((obj) => {
    return Object.values(obj)
      .map((value) => {
        if (value === undefined) {
          return "";
        } else {
          return `"${value.toString().replace(/"/g, '""')}"`;
        }
      })
      .join(",");
  });

  return [headers, ...rows].join("\r\n");
};

function Reports({
  startDate,
  endDate,
  selectedContentType,
  selectedListings,
  selectedLocations,
  setCsvUrl,
}: ReportsProps) {
  const client = useGqlClient();
  const [showExampleData, setShowExampleData] = useState(false);
  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const brandId = useSelector(authSelectors.activeBrandId);

  const [sortProperty, setSortProperty] = useState<BookingPostsSortBy>(
    BookingPostsSortBy.BookingPostsSortByImpressions
  );

  const { data, isLoading, error } = useReportsQuery(
    client,
    {
      filters: {
        startDate: startDate ? getUnixTime(startDate) : undefined,
        endDate: endDate ? getUnixTime(endDate) : undefined,
        sortBy: sortProperty,
        listingIDs: selectedListings,
        locationIDs: selectedLocations,
        contentTypes: selectedContentType,
      },
      brandID: brandId ? brandId : "",
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    if (!data || !data.brand) {
      return;
    }

    const currentPlan = data.billingPlans.find(
      (bp) => bp.id === data.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt = !currentPlan
      ? false
      : currentPlan &&
        currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
      ? true
      : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);

    if (data.reportMetrics.metrics.length === 0) {
      setCsvUrl(null);
    } else {
      const csvString = convertArrayToCSV(data.reportMetrics.metrics);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      setCsvUrl(url);
    }
  }, [data, setCsvUrl]);

  if (error) {
    return (
      <Flex align="center" justify="center" direction="column">
        <Text>Something went wrong</Text>
      </Flex>
    );
  }

  if (!data || isLoading) {
    return <Loading />;
  }

  if (showExampleData) {
    return (
      <>
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <CTA
            type="tertiary"
            margin="0 0 0"
            onClick={() => setShowExampleData(false)}
            to="#"
          >
            Hide Example Report
          </CTA>
        </div>
        <StatsGrid>
          <StatsCard
            key={"applications"}
            label="Applications"
            value={39}
            breakdown={[
              {
                label: "Approved",
                value: 19,
              },
              {
                label: "Rejected",
                value: 2,
              },
              {
                label: "Approval",
                value: Math.round((19 / 39) * 100),
              },
            ]}
            previousValue={10}
          />
          <StatsCard
            key={"bookings"}
            label="Bookings"
            breakdown={[
              {
                label: "Completed",
                value: 18,
              },
              {
                label: "Upcoming",
                value: 2,
              },
              {
                label: "In Progress",
                value: 1,
              },
            ]}
            value={21}
            previousValue={9}
          />
          <StatsCard
            key={"content"}
            label="Content"
            breakdown={[
              { label: "Posts", value: 6 },
              { label: "Reels", value: 13 },
              { label: "Stories", value: 55 },
              { label: "TikTok", value: 5 },
            ]}
            value={79}
            previousValue={44}
          />
          <StatsCard
            key={"engagement"}
            label="Engagement Rate"
            breakdown={[
              { label: "Likes", value: 2657 },
              {
                label: "Comments",
                value: 940,
              },
              { label: "Shares", value: 32 },
              { label: "Saves", value: 197 },
            ]}
            value={Math.round(
              ((2657 + 940 + 32 + 197) / exampleReportData.recent.reach.total) *
                100
            )}
            previousValue={4.9}
          />
        </StatsGrid>
        <ChartsGrid>
          <StatsCard
            label="Total Views"
            value={exampleReportData.recent.impressions.total}
            previousValue={exampleReportData.trailing.impressions.total}
            metrics={[
              {
                label: "Last 30 days",
                data: exampleReportData.recent.impressions.data,
                lineColor: "primary",
              },
              {
                label: "Previous 30 days",
                data: exampleReportData.trailing.impressions.data,
                lineColor: "secondary",
              },
            ]}
          />
          <StatsCard
            label="Total Engagements"
            value={exampleReportData.recent.engagements.total}
            previousValue={exampleReportData.trailing.engagements.total}
            metrics={[
              {
                label: "Last 30 days",
                data: exampleReportData.recent.engagements.data,
                lineColor: "primary",
              },
              {
                label: "Previous 30 days",
                data: exampleReportData.trailing.engagements.data,
                lineColor: "secondary",
              },
            ]}
          />
        </ChartsGrid>

        <Flex
          margin="m 0"
          direction="row"
          justify="space-between"
          align="baseline"
        >
          <H3 margin="xxl 0 m">Top Content</H3>
          <Dropdown
            selectedOptions={[BookingPostsSortBy.BookingPostsSortByImpressions]} // Passed as an array, even for single selection
            setSelectedOptions={(selectedOptions) => {
              const selectedValue = selectedOptions[0] as BookingPostsSortBy; // Always an array, so access first element
              setSortProperty(selectedValue);
            }}
            renderLabel={() => {
              return `Sort by ${
                sortOptions.filter((e) => e.value === sortProperty)[0].label
              }`;
            }}
            dropdownType="text"
            selectionMode="single"
            options={sortOptions}
          />
        </Flex>

        <PostsGrid>
          {exampleReportPosts.map((bp) => {
            return (
              <BookingPost
                exampleData={true}
                key={bp.id}
                name={
                  bp.postType === BookingPostType.BookingPostTypeTiktok &&
                  bp.creator.tikTok
                    ? bp.creator.tikTok.name
                    : bp.creator.instagram
                    ? bp.creator.instagram.name
                    : ""
                }
                username={
                  bp.postType === BookingPostType.BookingPostTypeTiktok &&
                  bp.creator.tikTok
                    ? bp.creator.tikTok.username
                    : bp.creator.instagram
                    ? bp.creator.instagram.username
                    : ""
                }
                mediaUrl={bp.mediaUrl}
                thumbnailUrl={bp.thumbnailUrl}
                caption={bp.mediaCaption}
                mediaType={bp.mediaType as BookingPostMediaType}
                postType={bp.postType as BookingPostType}
                id={bp.id}
                showCreator={true}
                square={true}
              />
            );
          })}
        </PostsGrid>
      </>
    );
  }

  if (showUpgradePrompt) {
    return (
      <>
        <Flex style={{ marginTop: "96px" }} direction="column" align="center">
          <Text
            isCompact
            weight="semi"
            margin="0"
            onClick={() => setShowUpgradeOverlay(true)}
            style={{ cursor: "pointer" }}
          >
            Upgrade plan to access reports
          </Text>
        </Flex>
        {showUpgradeOverlay && (
          <UpgradePlan onCancel={() => setShowUpgradeOverlay(false)} />
        )}
      </>
    );
  }

  if (data.brand && !data.brand.fbReportingEnabled) {
    return (
      <>
        <Flex style={{ marginTop: "96px" }} direction="column" align="center">
          <Text isCompact weight="semi" margin="0">
            No data
          </Text>
          <Text weight="semi" isCompact margin="s 0 xl" colorPreset="secondary">
            Get started by connecting your socials
          </Text>
          <div>
            <NewButton label="Connect socials" to="/b/settings" />
          </div>
        </Flex>
      </>
    );
  }

  return (
    <>
      {data.bookingPosts.bookingPosts.length > 0 && (
        <>
          <StatsGrid>
            <StatsCard
              key={"applications"}
              label="Applications"
              value={data.bookingsMetrics.totalApplications}
              breakdown={[
                {
                  label: "Approved",
                  value: data.bookingsMetrics.totalBookings,
                },
                {
                  label: "Rejected",
                  value: data.bookingsMetrics.totalRejectedBookings,
                },
                {
                  label: "Cancelled",
                  value: data.bookingsMetrics.totalCancelledBookings,
                },
                {
                  label: "Approval",
                  value: Math.round(
                    (data.bookingsMetrics.totalBookings /
                      data.bookingsMetrics.totalApplications) *
                      100
                  ),
                },
              ]}
              // previousValue={1}
            />
            <StatsCard
              key={"bookings"}
              label="Bookings"
              breakdown={[
                {
                  label: "Completed",
                  value: data.bookingsMetrics.totalCompletedBookings,
                },
                {
                  label: "Upcoming",
                  value: data.bookingsMetrics.totalUpcomingBookings,
                },
                {
                  label: "In Progress",
                  value: data.bookingsMetrics.totalOverdueBookings,
                },
              ]}
              value={data.bookingsMetrics.totalBookings}
              // previousValue={1}
            />
            <StatsCard
              key={"content"}
              label="Content"
              breakdown={[
                { label: "Posts", value: data.bookingsMetrics.totalPosts },
                { label: "Reels", value: data.bookingsMetrics.totalReels },
                { label: "Stories", value: data.bookingsMetrics.totalStories },
                { label: "TikTok", value: data.bookingsMetrics.totalTiktoks },
              ]}
              value={
                data.bookingsMetrics.totalPosts +
                data.bookingsMetrics.totalReels +
                data.bookingsMetrics.totalStories +
                data.bookingsMetrics.totalTiktoks
              }
              // previousValue={1}
            />
            <StatsCard
              key={"engagement"}
              label="Engagement Rate"
              breakdown={[
                { label: "Likes", value: data.bookingsMetrics.totalLikes },
                {
                  label: "Comments",
                  value: data.bookingsMetrics.totalComments,
                },
                { label: "Shares", value: data.bookingsMetrics.totalShares },
                { label: "Saves", value: data.bookingsMetrics.totalSaves },
              ]}
              value={
                data.reportMetrics.totalReach > 0
                  ? Math.round(
                      (data.reportMetrics.totalEngagement /
                        data.reportMetrics.totalReach) *
                        100
                    )
                  : 0
              }
              // previousValue={99}
            />
          </StatsGrid>
          <ChartsGrid>
            <StatsCard
              key={"views"}
              label="Total Views" // string matched on StatsCard
              value={data.reportMetrics.totalImpressions}
              // previousValue={data.reportMetrics.prevTotalImpressions}
              metrics={[
                {
                  label: "Current",
                  data: data.reportMetrics.metrics.map((rm) => {
                    return [rm.timestamp * 1000, rm.impressions];
                  }),
                  lineColor: "primary",
                },
                {
                  label: "Previous",
                  data: data.reportMetrics.trailingMetrics.map((rm) => {
                    return [rm.timestamp * 1000, rm.impressions];
                  }),
                  lineColor: "secondary",
                },
              ]}
            />
            <StatsCard
              key={"engagements"}
              label="Total Engagements" // string matched on StatsCard
              value={data.reportMetrics.totalEngagement}
              // previousValue={data.reportMetrics.prevTotalEngagement}
              metrics={[
                {
                  label: "Current",
                  data: data.reportMetrics.metrics.map((rm) => {
                    return [rm.timestamp * 1000, rm.engagement];
                  }),
                  lineColor: "primary",
                },
                {
                  label: "Previous",
                  data: data.reportMetrics.trailingMetrics.map((rm) => {
                    return [rm.timestamp * 1000, rm.engagement];
                  }),
                  lineColor: "secondary",
                },
              ]}
            />
          </ChartsGrid>

          <Flex
            margin="m 0"
            direction="row"
            justify="space-between"
            align="baseline"
          >
            <H3 margin="xxl 0 m">Top Content</H3>
            <Dropdown
              selectedOptions={[
                BookingPostsSortBy.BookingPostsSortByImpressions,
              ]}
              setSelectedOptions={(selectedOptions) => {
                const selectedValue = selectedOptions[0] as BookingPostsSortBy;
                setSortProperty(selectedValue);
              }}
              renderLabel={() => {
                return `Sort by ${
                  sortOptions.filter((e) => e.value === sortProperty)[0].label
                }`;
              }}
              selectionMode="single"
              options={sortOptions}
              dropdownType="text"
            />
          </Flex>
          <PostsGrid>
            {data.bookingPosts.bookingPosts.slice(0, 10).map((bp) => {
              let value = 0;
              switch (sortProperty) {
                case BookingPostsSortBy.BookingPostsSortByEngagement:
                  value = bp.engagement;
                  break;
                case BookingPostsSortBy.BookingPostsSortByReach:
                  value = bp.reach;
                  break;
                case BookingPostsSortBy.BookingPostsSortByImpressions:
                  value = bp.impressions;
                  break;
                default:
                  value = bp.impressions;
              }

              return (
                <BookingPost
                  key={bp.id}
                  name={
                    bp.postType === BookingPostType.BookingPostTypeTiktok &&
                    bp.creator.tikTok
                      ? bp.creator.tikTok.displayName
                      : bp.creator.instagram
                      ? bp.creator.instagram.name
                      : ""
                  }
                  username={
                    bp.postType === BookingPostType.BookingPostTypeTiktok &&
                    bp.creator.tikTok
                      ? bp.creator.tikTok.username
                      : bp.creator.instagram
                      ? bp.creator.instagram.username
                      : ""
                  }
                  mediaUrl={bp.mediaUrl}
                  metricValue={value}
                  caption={bp.mediaCaption}
                  mediaType={bp.mediaType}
                  thumbnailUrl={bp.thumbnailUrl}
                  postType={bp.postType}
                  id={bp.id}
                  square={true}
                  showCreator={true}
                  maxWidth={500}
                />
              );
            })}
          </PostsGrid>
          <Flex justify="center" margin="0 0 0">
            {data.bookingPosts.bookingPosts &&
              data.bookingPosts.bookingPosts.length >= 10 && (
                <TextLink margin="0 0 xxl" to="/b/content">
                  See more
                </TextLink>
              )}
          </Flex>
        </>
      )}
      {data.bookingPosts.bookingPosts.length === 0 && (
        <Flex
          style={{ marginTop: "120px" }}
          margin="0"
          direction="column"
          align="center"
        >
          <Text isCompact weight="semi" margin="0" colorPreset="secondary">
            No data for this date range
          </Text>
          <div>
            <CTA
              type="tertiary"
              margin="l 0 0"
              onClick={() => setShowExampleData(true)}
              to="#"
            >
              Show Example Report
            </CTA>
          </div>
        </Flex>
      )}
    </>
  );
}
