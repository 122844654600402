import * as React from "react";
import styled from "../../../styles";
import theme from "../../../styles/theme";

type colors = typeof theme.color.typography;

const TikTokIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.6191 6.4517C21.2441 6.15758 19.999 5.43197 19.0653 4.38065C18.1316 3.32933 17.5582 2.00726 17.4285 0.60717V0H12.6854V18.8237C12.6825 19.657 12.4185 20.4685 11.9306 21.1441C11.4427 21.8197 10.7554 22.3254 9.96521 22.5902C9.17505 22.855 8.32178 22.8656 7.5253 22.6204C6.72882 22.3753 6.02917 21.8867 5.52467 21.2234C5.01234 20.549 4.72814 19.729 4.71328 18.8822C4.69841 18.0354 4.95367 17.2059 5.44202 16.5139C5.93037 15.822 6.62644 15.3036 7.42928 15.0339C8.23211 14.7642 9.09998 14.7572 9.90704 15.014V10.1952C8.10736 9.94644 6.27492 10.2664 4.66603 11.1103C3.05715 11.9543 1.75229 13.28 0.933968 14.9021C0.115651 16.5242 -0.175196 18.3614 0.102111 20.157C0.379417 21.9525 1.21101 23.6164 2.48056 24.916C3.69126 26.1548 5.24365 27.0047 6.93955 27.3573C8.63546 27.7098 10.398 27.549 12.0021 26.8954C13.6062 26.2417 14.9791 25.1249 15.9456 23.6875C16.9121 22.25 17.4284 20.5572 17.4285 18.825V9.20669C19.3454 10.5771 21.6437 11.3119 24 11.3077V6.59489C23.536 6.59546 23.0731 6.54747 22.6191 6.4517Z"
      fill="black"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const TikTokIconSimple = styled(TikTokIconSVG)<Props>`
  margin-right: 2px;
  margin-top: 1px;
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
