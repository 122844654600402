import { differenceInMinutes, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CTA } from "../../../components/CTA";
import { Card } from "../../../components/Card";
import { CardLink } from "../../../components/CardLink";
import { Chevron } from "../../../components/Chevron";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H1, H2, H3, H4 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { NewButton } from "../../../components/NewButton";
import { Tab } from "../../../components/Tab";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { ListIcon } from "../../../components/icons/ListIcon";
import { TimelineIcon } from "../../../components/icons/TimelineIcon";
import { LARGE_DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../../config";
import {
  BrandListingsQuery,
  ListingStatus,
  useBrandListingsQuery,
  useBrandLocationsQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { styled } from "../../../styles";
import { CalendarView } from "./CalendarView";
import { ListView } from "./ListView";

export enum MobileListingTabs {
  Active = "active",
  Scheduled = "scheduled",
  Draft = "draft",
  Completed = "completed",
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: ${LARGE_DESKTOP_BREAKPOINT}px;
  padding: 0 ${(p) => p.theme.spacing.xxxl} ${(p) => p.theme.spacing.xl};
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};
    max-height: unset;
  }
`;

const ListingsWrap = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  overflow: hidden;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s} 0 0;
  margin-top: ${(p) => p.theme.spacing.s};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export interface MatchParams {
  page: string;
}

export const BrandListings = () => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showListingModal, setShowListingModal] = useState(false);
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const client = useGqlClient();
  const { data, isLoading } = useBrandListingsQuery(
    client,
    { brandId: activeBrandId ?? "" },
    {
      retry: false,
      cacheTime: 0, // Disable caching completely
      refetchOnWindowFocus: false,
    }
  );

  const history = useHistory();
  const q = new URLSearchParams(history.location.search);
  const r = q.get("r");

  useEffect(() => {
    if (r === "onboarding") {
      setShowOnboardingModal(true);
    } else if (r === "active") {
      // TODO move this logic to the backend at some stage
      if (
        data &&
        data.listings.listings.length === 1 &&
        data.listings.listings[0].publishDate &&
        differenceInMinutes(
          new Date(),
          fromUnixTime(data.listings.listings[0].publishDate)
        ) < 5
      ) {
        setShowListingModal(true);
      }
    }
  }, [r, data]);

  return (
    <Wrap>
      <Modal
        isOpen={showOnboardingModal}
        setIsOpen={setShowOnboardingModal}
        maxWidth={480}
      >
        <H2 margin={"s 0 0"}>Almost there 🎉</H2>
        <Text size="m" margin={"xl 0"}>
          The last step is to set up a campaign — these are offers you run for
          influencers.
          <br />
          <br />
          Describe what you're offering, and what you'd like in return. Target
          influencers by category, dietary preferences, minimum followers, and
          more.
        </Text>
        <CTA to="#" onClick={() => setShowOnboardingModal(false)}>
          Got it
        </CTA>
      </Modal>
      <Modal
        isOpen={showListingModal}
        setIsOpen={setShowListingModal}
        maxWidth={480}
      >
        <H2 margin="xs 0 0">Nice work 💪</H2>
        <Text margin={"xl 0"}>
          You've created your first live campaign. Matching influencers will now
          be able to apply.
          <br />
          <br />
          We'll let you know via email, so sit back, relax and watch the
          applications roll in.
        </Text>
        <CTA to="#" onClick={() => setShowListingModal(false)}>
          Got it
        </CTA>
      </Modal>
      <Flex justify="space-between" align="center" margin="0 0 s">
        <H1 margin="0 0">Campaigns</H1>
        <div>
          <NewButton label="New Campaign" to="/b/create-listing" />
        </div>
      </Flex>
      <Listings data={data} isLoading={isLoading} />
    </Wrap>
  );
};

enum ListingTabs {
  List = "list",
  Timeline = "timeline",
}

const Listings = ({
  data,
  isLoading,
}: {
  data: BrandListingsQuery | undefined;
  isLoading: boolean;
}) => {
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const [tab, setTab] = useState<ListingTabs>(() => {
    // Initialize tab state from localStorage
    const savedTab = localStorage.getItem("listingViewPreference");
    return savedTab ? (savedTab as ListingTabs) : ListingTabs.List;
  });
  const [page, setPage] = useState<MobileListingTabs>(MobileListingTabs.Active);
  const [locationFilter, setLocationFilter] = useState<string[]>([]);

  useEffect(() => {
    // Save tab preference to localStorage whenever it changes
    localStorage.setItem("listingViewPreference", tab);
  }, [tab]);

  const { data: locationsData } = useBrandLocationsQuery(
    client,
    {
      brandId: activeBrandId ?? "",
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (locationsData?.brand?.locations?.locations) {
      setLocationFilter(
        locationsData.brand.locations.locations.map((l) => l.id)
      );
    }
  }, [locationsData]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load your campaigns, please try again later.
        </Text>
      </Wrap>
    );
  }

  // If no location filter is selected, show all listings
  // If all locations are selected, show all listings
  const filteredListings = data.listings.listings.filter((listing) =>
    locationFilter.length === 0 ||
    locationFilter.length === locationsData?.brand?.locations?.locations?.length
      ? true
      : listing.locations.some((location) =>
          locationFilter.includes(location.id)
        )
  );

  const isMobile = window && window.innerWidth < MOBILE_BREAKPOINT;

  if (isMobile) {
    const active = filteredListings.filter(
      (l) => l.status === ListingStatus.StatusActive
    );

    const scheduled = filteredListings.filter(
      (l) => l.status === ListingStatus.StatusScheduled
    );

    const draft = filteredListings.filter(
      (l) => l.status === ListingStatus.StatusDraft
    );

    const completed = filteredListings
      .filter(
        (l) =>
          l.status === ListingStatus.StatusExpired ||
          l.status === ListingStatus.StatusUnknown
      )
      .sort((a, b) => {
        return b.endDate! - a.endDate!;
      });

    let listingsToRender = filteredListings;
    switch (page) {
      case MobileListingTabs.Active:
        listingsToRender = active;
        break;
      case MobileListingTabs.Scheduled:
        listingsToRender = scheduled;
        break;
      case MobileListingTabs.Draft:
        listingsToRender = draft;
        break;
      case MobileListingTabs.Completed:
        listingsToRender = completed;
        break;
    }

    return (
      <View>
        <HorizontalScroll>
          <MobileTabWrap margin="0 0 l" justify="flex-start">
            <Tab
              textAlign="left"
              active={page === MobileListingTabs.Active}
              label="Active"
              onClick={() => setPage(MobileListingTabs.Active)}
              badgeCount={active.length}
              hideInactiveBorder
              textSize="m"
            />
            <Tab
              textAlign="left"
              active={page === MobileListingTabs.Scheduled}
              label="Scheduled"
              onClick={() => setPage(MobileListingTabs.Scheduled)}
              badgeCount={scheduled.length}
              hideInactiveBorder
              textSize="m"
            />
            <Tab
              textAlign="left"
              active={page === MobileListingTabs.Draft}
              label="Draft"
              onClick={() => setPage(MobileListingTabs.Draft)}
              badgeCount={draft.length}
              hideInactiveBorder
              textSize="m"
            />
            <Tab
              textAlign="left"
              active={page === MobileListingTabs.Completed}
              label="Completed"
              onClick={() => setPage(MobileListingTabs.Completed)}
              badgeCount={completed.length}
              hideInactiveBorder
              textSize="m"
            />
          </MobileTabWrap>
        </HorizontalScroll>
        <RenderListings listings={listingsToRender} />
      </View>
    );
  }

  return (
    <ListingsWrap>
      <Flex justify="space-between" margin="0 m m m">
        <TabWrap margin="0 s 0" justify="flex-start" align="center">
          <Tab
            textAlign="left"
            active={tab === ListingTabs.List}
            underLineWidth={80}
            icon={
              <ListIcon
                width={18}
                colorPreset={
                  tab === ListingTabs.List ? "secondaryHeading" : "text"
                }
              />
            }
            label="List"
            onClick={() => setTab(ListingTabs.List)}
            textSize="m"
          />
          <Tab
            new
            textAlign="left"
            active={tab === ListingTabs.Timeline}
            label="Calendar"
            underLineWidth={110}
            icon={
              <TimelineIcon
                width={18}
                colorPreset={
                  tab === ListingTabs.Timeline ? "secondaryHeading" : "text"
                }
              />
            }
            onClick={() => setTab(ListingTabs.Timeline)} // TODO: remove this until finished
            textSize="m"
          />
        </TabWrap>
        {locationsData?.brand?.locations?.locations?.length ? (
          <Dropdown
            showSelectAll
            disableOptionSort
            options={[
              ...locationsData.brand.locations.locations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
            ]}
            width={240}
            selectedOptions={locationFilter}
            setSelectedOptions={(value) => setLocationFilter(value)}
            renderLabel={() => {
              if (
                !locationsData?.brand?.locations?.locations ||
                locationFilter.length === 0
              ) {
                return "0 Locations";
              }

              if (
                locationFilter.length ===
                locationsData.brand.locations.locations.length
              ) {
                return "All Locations";
              }

              return `${locationFilter.length} Locations`;
            }}
            dropdownType="text"
            selectionMode="multiple"
          />
        ) : null}
        <Divider />
      </Flex>
      {tab === ListingTabs.List && <ListView listings={filteredListings} />}
      {tab === ListingTabs.Timeline && (
        <CalendarView listings={filteredListings} />
      )}
    </ListingsWrap>
  );
};

const RenderListings = ({
  listings,
}: {
  listings: BrandListingsQuery["listings"]["listings"];
}) => {
  if (listings.length === 0) {
    return (
      <Text margin="xl 0 0" align="center">
        No campaigns
      </Text>
    );
  }

  return listings.map((l) => (
    <CardLink to={`/b/listings/${l.id}`} key={l.id}>
      <StyledCard hover padding="s l" margin="0 0 m 0">
        <Flex>
          <CardContent>
            <TruncatedH4 margin="s 0 xs 0">{l.name}</TruncatedH4>
            <Flex margin="m 0 0">
              <View margin="0 l 0 0">
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.locations.length}
                </Text>
                <Text margin="0" isCompact size="s">
                  {l.locations.length === 1 ? "Location" : "Locations"}
                </Text>
              </View>
              <View margin="0 l 0 0">
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.applicationsCount}
                </Text>
                <Text margin="0" isCompact size="s">
                  Applications
                </Text>
              </View>
              <View>
                <Text size="l" margin="0" weight="bold" isCompact>
                  {l.bookingsCount}
                </Text>
                <Text margin="0" isCompact size="s">
                  Bookings
                </Text>
              </View>
            </Flex>
          </CardContent>
          <CardRight>
            <Chevron width={12} height={12} />
          </CardRight>
        </Flex>
      </StyledCard>
    </CardLink>
  ));
};

const Divider = styled.div`
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 1px;
  background: ${(p) => p.theme.color.divider};
`;

const TabWrap = styled(Flex)`
  & > *:last-child {
    margin-left: 0px;
  }

  flex: 1;
  display: flex;
  z-index: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

const HorizontalScroll = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
`;

const MobileTabWrap = styled(Flex)`
  & > *:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.l};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

const CardContent = styled.div`
  flex: 1;
`;

const CardRight = styled.div`
  display: flex;
  align-items: center;
}
`;

const StyledCard = styled(Card)`
  position: relative;
  text-align: left;
`;

const TruncatedH4 = styled(H4)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
