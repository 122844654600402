import {
  fromUnixTime,
  getUnixTime,
  isFuture,
  isPast,
  isSameDay,
  startOfDay,
} from "date-fns";
import { Action } from "history";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { toast } from "sonner";
import { BackLink } from "../../../components/BackLink";
import { Card } from "../../../components/Card";
import { Chevron } from "../../../components/Chevron";
import { Button, StyledButtonWrap } from "../../../components/CTA";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { PlayIcon } from "../../../components/icons/PlayIcon";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { ShareLink } from "../../../components/ShareLink";
import { Underline } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT, XL_DESKTOP_BREAKPOINT } from "../../../config";
import {
  ApprovalSettingsInput,
  ApprovalType,
  BillingStatus,
  BookingLimitPeriod,
  BookingLimitScope,
  Days,
  DuplicateListingMutation,
  Listing,
  ListingOfferInput,
  ListingStatus,
  ListingType,
  Platform,
  ScheduleInput,
  useBrandBillingQuery,
  useBrandListingQuery,
  useCategoriesQuery,
  useCreateListingMutation,
  useDeleteListingMutation,
  useDuplicateListingMutation,
  useUpdateListingMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled, { ThemeProp } from "../../../styles";
import { Checklist, ChecklistWrap } from "../Billing";
import ApprovalSettings from "./ApprovalSettings";
import Availability from "./availability";
import ListingDetails from "./ListingDetails";
import { InstagramOffers } from "./offers/Instagram";
import { TikTokOffers } from "./offers/TikTok";
import { Preview } from "./Preview";

const todayStartOfDay = startOfDay(new Date());

export const ListingForm = () => {
  const theme = useTheme();
  const [isPreview, setIsPreview] = useState(false);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [dietaryPreferences, setDietaryPreferences] = useState<
    Listing["dietaryPreferences"]
  >([]);
  const [images, setImages] = useState<string[]>([]);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [ageRestrictions, setAgeRestrictions] = useState<number | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState("");

  const [instagramHandles, setInstagramHandles] = useState<string[]>([]);

  const [tiktokHandles, setTiktokHandles] = useState<string[]>([]);

  const [instagramTags, setInstagramTags] = useState<string[]>([]);
  const [tiktokTags, setTiktokTags] = useState<string[]>([]);

  const [posts, setPosts] = useState(0);
  const [reels, setReels] = useState(1);
  const [stories, setStories] = useState(2);
  const [tiktoks, setTiktoks] = useState(0);
  const [requirePostAndReel, setRequirePostAndReel] = useState(true);
  const [listingType, setListingType] = useState<ListingType>(
    ListingType.Ongoing
  );
  const [listingStatus, setListingStatus] = useState<ListingStatus>(
    ListingStatus.StatusDraft
  );
  const [days, setDays] = useState<Days[]>([
    Days.Sunday,
    Days.Monday,
    Days.Tuesday,
    Days.Wednesday,
    Days.Thursday,
    Days.Friday,
    Days.Saturday,
  ]);

  const [bookingLimitValue, setBookingLimitValue] = useState<number | null>(
    null
  );
  const [bookingLimitPeriod, setBookingLimitPeriod] =
    useState<BookingLimitPeriod | null>(null);
  const [bookingLimitScope, setBookingLimitScope] =
    useState<BookingLimitScope | null>(null);

  const [startDate, setStartDate] = useState<Date>(todayStartOfDay);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [locations, setLocations] = useState<string[]>([]);
  const [visibleFromDate, setVisibleFromDate] = useState<Date | null>(null);
  const [schedules, setSchedules] = useState<ScheduleInput[]>([]);
  const [timeSlots, setTimeSlots] = useState<number[]>([]);
  const [approvalSettings, setApprovalSettings] =
    useState<ApprovalSettingsInput>({
      type: ApprovalType.ApprovalTypeManual,
    });
  // const [autoApprovalLimit, setAutoApprovalLimit] =
  //   useState<AutoApprovalLimit | null>(null);
  const [internalBookingNotes, setInternalBookingNotes] = useState("");

  const [instagramOffers, setInstagramOffers] = useState<ListingOfferInput[]>(
    []
  );
  const [tiktokOffers, setTiktokOffers] = useState<ListingOfferInput[]>([]);

  // Combine Instagram and TikTok offers
  const offers = [...instagramOffers, ...tiktokOffers];
  const numberOfVisitors =
    (offers.sort((a, b) => a.maxGuests - b.maxGuests)[0]?.maxGuests || 0) + 1;

  // Design My Night booking type ID for generating availability
  const [dmnBookingTypeId, setDmnBookingTypeId] = useState<string>("");

  const [requiredDaysNotice, setRequiredDaysNotice] = useState(3);
  const [applicationsCount, setApplicationsCount] = useState(0);

  // Design My Night booking type ID for creating bookings
  // const [collinsBookingCreationTypeId, setCollinsBookingCreationTypeId] =
  //   useState<string>("");
  const [collinsAutoCreate, setCollinsAutoCreate] = useState(false);

  const client = useGqlClient();
  let { id } = useParams<{ id: string }>();
  const { data: listingData, isLoading: listingLoading } = useBrandListingQuery(
    client,
    {
      id,
    },
    {
      cacheTime: 0, // Disable caching completely
      refetchOnWindowFocus: false,
    }
  );

  const { data: categoriesData } = useCategoriesQuery(client);

  const updateListing = useUpdateListingMutation(client);
  const { track } = useAnalytics();
  const brand = useSelector(authSelectors.activeBrand);
  const createListing = useCreateListingMutation(client);
  const duplicateListing = useDuplicateListingMutation(client);
  const queryClient = useQueryClient();
  const history = useHistory();
  const deleteListing = useDeleteListingMutation(client);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const initialValuesRef = useRef<any>(null);
  const { data: brandBilling } = useBrandBillingQuery(client, {
    brandID: brand?.id || "",
  });

  // Add refs for each section that might contain errors
  const nameRef = useRef<HTMLInputElement>(null);
  const detailsRef = useRef<HTMLTextAreaElement>(null);
  const imagesRef = useRef<HTMLDivElement>(null);
  const instagramOffersRef = useRef<HTMLDivElement>(null);
  const tiktokOffersRef = useRef<HTMLDivElement>(null);
  const locationsRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const approvalSettingsRef = useRef<HTMLDivElement>(null);
  const daysRef = useRef<HTMLDivElement>(null);
  const isNew = id ? false : true;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [requiresUpgrade, setRequiresUpgrade] = useState(false);
  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const { showNewMessages } = useIntercom();

  const handleNameChange = (newName: string) => {
    setName(newName);
    if (errors.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }
  };

  const handleDetailsChange = (newDetails: string) => {
    setDetails(newDetails);
    if (errors.details) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        details: "",
      }));
    }
  };

  const handleImagesChange = (newImages: string[]) => {
    setImages(newImages);
    if (errors.images) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        images: "",
      }));
    }
  };

  const handleCategoriesChange = (newCategories: string[]) => {
    setCategories(newCategories);
    if (errors.categories && newCategories.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        categories: "",
      }));
    }
  };

  const handleApprovalSettingsChange = (
    newApprovalSettings: ApprovalSettingsInput
  ) => {
    setApprovalSettings(newApprovalSettings);
    if (errors.approvalSettings) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        approvalSettings: "",
      }));
    }
  };

  const handleDaysChange = (newDays: Days[]) => {
    setDays(newDays);
    if (errors.days) {
      setErrors((prevErrors) => ({ ...prevErrors, days: "" }));
    }
  };

  // New error state variables
  const [errors, setErrors] = useState({
    name: "",
    details: "",
    images: "",
    endDate: "",
    offers: {
      all: "",
      instagram: [] as string[],
      tiktok: [] as string[],
    },
    locations: "",
    categories: "",
    approvalSettings: "",
    days: "",
  });

  const scrollToError = () => {
    if (errors.name) {
      nameRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.details) {
      detailsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.images) {
      imagesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (
      errors.offers.all !== "" ||
      errors.offers.instagram.some((error) => error !== "")
    ) {
      instagramOffersRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.offers.tiktok.some((error) => error !== "")) {
      tiktokOffersRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.locations) {
      locationsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.categories) {
      categoriesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.approvalSettings) {
      approvalSettingsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (errors.days) {
      daysRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    return;
  };

  const validateName = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      details: "",
      images: "",
      offers: {
        all: "",
        instagram: [] as string[],
        tiktok: [] as string[],
      },
      locations: "",
      categories: "",
      approvalSettings: "",
      days: "",
      endDate: "",
      errorSummary: "",
      errorCount: 0,
    };

    if (!name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    return { isValid, newErrors };
  };

  const validatePublish = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      details: "",
      images: "",
      offers: {
        all: "",
        instagram: [] as string[],
        tiktok: [] as string[],
      },
      locations: "",
      categories: "",
      approvalSettings: "",
      days: "",
      endDate: "",
      errorSummary: "",
      errorCount: 0,
    };

    if (!name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "Name is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (!details.trim()) {
      newErrors.details = "Details are required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "Details are required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (endDate && isPast(endDate)) {
      newErrors.endDate = "End date must be in the future";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "End date must be in the future"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (images.length === 0) {
      newErrors.images = "At least one image is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one image is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (categories.length === 0) {
      newErrors.categories = "At least one category is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one category is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (offers.length === 0) {
      newErrors.offers.all = "At least one offer is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one offer is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    // Validate Instagram offers
    newErrors.offers.instagram = instagramOffers.map((offer, index) =>
      !offer.offer.trim() ? `Offer ${index + 1} is empty` : ""
    );
    if (newErrors.offers.instagram.some((error) => error !== "")) {
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one Instagram offer is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    // Validate TikTok offers
    newErrors.offers.tiktok = tiktokOffers.map((offer, index) =>
      !offer.offer.trim() ? `Offer ${index + 1} is empty` : ""
    );
    if (newErrors.offers.tiktok.some((error) => error !== "")) {
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one TikTok offer is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (
      days.length === 0 &&
      (listingType === ListingType.Ongoing ||
        listingType === ListingType.RedeemAnytime)
    ) {
      newErrors.days = "At least one day is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one day is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (locations && locations.length === 0) {
      newErrors.locations = "At least one location is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one location is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    if (
      approvalSettings.type === ApprovalType.ApprovalTypeAutoWithCriteria &&
      !approvalSettings.minAvViews &&
      !approvalSettings.minEngagementRate &&
      !approvalSettings.minFollowers &&
      !approvalSettings.minUkAudience
    ) {
      newErrors.approvalSettings = "At least one approval criteria is required";
      isValid = false;
      newErrors.errorSummary =
        newErrors.errorSummary === ""
          ? "At least one approval criteria is required"
          : newErrors.errorSummary;
      newErrors.errorCount += 1;
    }

    return { isValid, newErrors };
  };

  const checkForChanges = useCallback(() => {
    if (!initialValuesRef.current) {
      // New listing - consider any input as a change
      return (
        name.trim() !== "" ||
        details.trim() !== "" ||
        images.length > 0 ||
        videoUrl !== "" ||
        categories.length !== categoriesData?.categories.length ||
        (dietaryPreferences && dietaryPreferences.length > 0)
      );
    }

    // Compare current values with initial values
    const hasChanges =
      name !== initialValuesRef.current.name ||
      details !== initialValuesRef.current.details ||
      !isEqual(images, initialValuesRef.current.images) ||
      videoUrl !== initialValuesRef.current.videoUrl ||
      !isEqual(
        categories ? [...categories].sort() : [],
        initialValuesRef.current.categories
          ? [...initialValuesRef.current.categories].sort()
          : []
      ) ||
      !isEqual(
        dietaryPreferences ? [...dietaryPreferences].sort() : [],
        [...initialValuesRef.current.dietaryPreferences].sort()
      ) ||
      ageRestrictions !== initialValuesRef.current.ageRestrictions ||
      phoneRequired !== initialValuesRef.current.phoneRequired ||
      !isEqual(instagramOffers, initialValuesRef.current.instagramOffers) ||
      !isEqual(instagramHandles, initialValuesRef.current.instagramHandles) ||
      !isEqual(instagramTags, initialValuesRef.current.instagramTags) ||
      !isEqual(tiktokOffers, initialValuesRef.current.tiktokOffers) ||
      !isEqual(tiktokHandles, initialValuesRef.current.tiktokHandles) ||
      !isEqual(tiktokTags, initialValuesRef.current.tiktokTags) ||
      posts !== initialValuesRef.current.requiredPosts ||
      reels !== initialValuesRef.current.requiredReels ||
      stories !== initialValuesRef.current.requiredStories ||
      requirePostAndReel !== initialValuesRef.current.requirePostAndReel ||
      !isSameDay(startDate, initialValuesRef.current.startDate) ||
      (endDate !== initialValuesRef.current.endDate &&
        endDate &&
        !isSameDay(endDate, initialValuesRef.current.endDate)) ||
      (visibleFromDate !== initialValuesRef.current.visibleFromDate &&
        visibleFromDate &&
        !isSameDay(
          visibleFromDate,
          initialValuesRef.current.visibleFromDate
        )) ||
      requiredDaysNotice !== initialValuesRef.current.bookingOffset ||
      days !== initialValuesRef.current.days ||
      !isEqual(
        locations ? [...locations].sort() : [],
        initialValuesRef.current.locations
          ? [...initialValuesRef.current.locations].sort()
          : []
      ) ||
      !isEqual(
        schedules ? [...schedules].sort() : [],
        initialValuesRef.current.schedules
          ? [...initialValuesRef.current.schedules].sort()
          : []
      ) ||
      !isEqual(
        timeSlots ? [...timeSlots].sort() : [],
        initialValuesRef.current.timeSlots
          ? [...initialValuesRef.current.timeSlots].sort()
          : []
      ) ||
      dmnBookingTypeId !== initialValuesRef.current.dmnBookingTypeId ||
      initialValuesRef.current.collinsBookingCreationTypeId ||
      bookingLimitValue !== initialValuesRef.current.bookingLimitValue ||
      bookingLimitScope !== initialValuesRef.current.bookingLimitScope ||
      bookingLimitPeriod !== initialValuesRef.current.bookingLimitPeriod ||
      approvalSettings !== initialValuesRef.current.approvalSettings ||
      isPrivate !== initialValuesRef.current.isPrivate;

    return hasChanges;
  }, [
    name,
    details,
    images,
    videoUrl,
    categories,
    dietaryPreferences,
    ageRestrictions,
    phoneRequired,
    instagramOffers,
    instagramHandles,
    instagramTags,
    tiktokOffers,
    tiktokHandles,
    tiktokTags,
    posts,
    reels,
    stories,
    requirePostAndReel,
    startDate,
    endDate,
    visibleFromDate,
    requiredDaysNotice,
    days,
    locations,
    schedules,
    timeSlots,
    dmnBookingTypeId,
    approvalSettings,
    bookingLimitValue,
    bookingLimitScope,
    bookingLimitPeriod,
    categoriesData?.categories.length,
    isPrivate,
  ]);

  const onSave = (status: ListingStatus, redirectToBilling?: boolean) => {
    const { isValid, newErrors } =
      status === ListingStatus.StatusActive
        ? validatePublish()
        : validateName();

    if (!isValid) {
      setErrors(newErrors);
      toast.error("Please fix the errors before publishing", {
        description: `${newErrors.errorSummary} ${
          newErrors.errorCount > 1 ? `(+${newErrors.errorCount - 1} more)` : ""
        }`,
        position: "top-right",
        duration: 5000,
      });
      scrollToError();
      return;
    }

    // to avoid race condition, set to true on failure
    setHasUnsavedChanges(false);

    if (status === ListingStatus.StatusActive) {
      if (requiresUpgrade && !redirectToBilling) {
        setShowUpgradeModal(true);
        return;
      }

      if (subscriptionCancelled) {
        setShowCancelModal(true);
        return;
      }
    }

    if (isNew) {
      createListing.mutate(
        {
          name: name,
          details: details,
          schedules:
            listingType === ListingType.Scheduled ? schedules : undefined,
          timeSlots: listingType === ListingType.Event ? timeSlots : undefined,
          requiresPhone: phoneRequired,
          startDate: getUnixTime(startDate),
          endDate: endDate ? getUnixTime(endDate) : undefined,
          locations,
          status,
          days,
          images,
          type: listingType,
          approvalSettings,
          categories,
          dietaryPreferences,
          brandId: brand ? brand.id : "",
          featuredVideo: videoUrl,
          offers,
          instagramHandles: instagramHandles,
          tikTokHandles: tiktokHandles,
          instagramTags: instagramTags,
          tikTokTags: tiktokTags,
          bookingOffset: requiredDaysNotice,
          minAge: ageRestrictions,
          visibleDate: visibleFromDate
            ? getUnixTime(visibleFromDate)
            : listingType === ListingType.Event
            ? getUnixTime(todayStartOfDay)
            : undefined,
          requiredPosts: posts,
          requiredReels: reels,
          requiredPostAndReel: requirePostAndReel,
          requiredStories: stories,
          requiredTikToks: tiktoks,
          dmnBookingTypeID: dmnBookingTypeId,
          collinsBookingAutoCreate: collinsAutoCreate,
          bookingLimitValue: bookingLimitValue,
          bookingLimitScope: bookingLimitScope,
          bookingLimitPeriod: bookingLimitPeriod,
          isPrivate: isPrivate,
        },
        {
          onSuccess: (res) => {
            toast.success(
              `${
                redirectToBilling
                  ? "Campaign saved to drafts"
                  : "Campaign created"
              }`
            );
            track("Create listing", {
              id: res.createListing.listing?.id
                ? res.createListing.listing.id
                : undefined,
              brand: brand ? brand.name : undefined,
              listingType: listingType ? listingType : ListingType.Ongoing,
            });

            setHasUnsavedChanges(false);

            queryClient.resetQueries(
              ["BrandListings", "BrandBilling", "BrandListing"],
              {
                exact: false,
              }
            );

            history.push(
              redirectToBilling
                ? "/b/billing"
                : status === ListingStatus.StatusActive
                ? "/b/listings?r=active"
                : "/b/listings"
            );
          },
          onError: () => {
            setHasUnsavedChanges(true);
            alert("Something went wrong");
          },
        }
      );
    } else {
      updateListing.mutate(
        {
          id,
          name: name,
          details: details,
          schedules:
            listingType === ListingType.Scheduled ? schedules : undefined,
          timeSlots: listingType === ListingType.Event ? timeSlots : undefined,
          requiresPhone: phoneRequired,
          startDate: getUnixTime(startDate),
          endDate:
            status === ListingStatus.StatusExpired &&
            listingType !== ListingType.Event
              ? getUnixTime(todayStartOfDay)
              : endDate
              ? getUnixTime(endDate)
              : undefined,
          locations,
          status,
          days,
          images,
          type: listingType,
          approvalSettings,
          categories,
          dietaryPreferences,
          minAge: ageRestrictions,
          featuredVideo: videoUrl,
          offers,
          instagramHandles: instagramHandles,
          tikTokHandles: tiktokHandles,
          instagramTags: instagramTags,
          requiredPosts: posts,
          requiredReels: reels,
          requiredPostAndReel: requirePostAndReel,
          requiredStories: stories,
          requiredTikToks: tiktoks,
          tikTokTags: tiktokTags,
          bookingOffset: requiredDaysNotice,
          visibleDate: visibleFromDate
            ? getUnixTime(visibleFromDate)
            : undefined,
          dmnBookingTypeID: dmnBookingTypeId,
          collinsBookingAutoCreate: collinsAutoCreate,
          bookingLimitValue: bookingLimitValue,
          bookingLimitScope: bookingLimitScope,
          bookingLimitPeriod: bookingLimitPeriod,
          isPrivate: isPrivate,
          // collins stuff
        },
        {
          onSuccess: (res) => {
            toast.success(
              `${
                redirectToBilling
                  ? "Campaign saved to drafts"
                  : "Campaign updated"
              }`
            );
            track("Edit campaign", {
              id: res.updateListing.id ? res.updateListing.id : undefined,
              brand: brand ? brand.name : undefined,
              listingType: listingType ? listingType : ListingType.Ongoing,
            });

            setHasUnsavedChanges(false);

            queryClient.resetQueries(
              ["BrandListings", "BrandBilling", "BrandListing"],
              {
                exact: false,
              }
            );

            history.push(
              redirectToBilling
                ? "/b/billing"
                : status === ListingStatus.StatusActive
                ? "/b/listings?r=active"
                : "/b/listings"
            );
          },
          onError: () => {
            setHasUnsavedChanges(true);
            alert("Something went wrong");
          },
        }
      );
    }
  };

  useEffect(() => {
    const listing = listingData?.listing;

    if (listingData && listing) {
      // Listing Details
      setName(listing.name);
      setDetails(listing.details ? listing.details : "");
      setImages(listing.images);
      setVideoUrl(listing.featuredVideo ? listing.featuredVideo : "");
      setCategories(listing.categories);
      setDietaryPreferences(listing.dietaryPreferences || []);
      setAgeRestrictions(listing.minAge || null);
      setPhoneRequired(listing.requiresPhone);

      // Instagram Offers
      setInstagramOffers(
        listing.offers.filter((o) => o.platform === Platform.Instagram)
      );
      setInstagramHandles(listing.instagramHandles);
      setInstagramTags(listing.instagramTags);
      setPosts(listing.requirements ? listing.requirements.requiredPosts : 0);
      setReels(listing.requirements ? listing.requirements.requiredReels : 0);
      setStories(
        listing.requirements ? listing.requirements.requiredStories : 0
      );
      setRequirePostAndReel(listing.requirements?.requiredReelAndPost || false);

      // TikTok Offers
      setTiktokOffers(
        listing.offers.filter((o) => o.platform === Platform.Tiktok)
      );
      setTiktokHandles(listing.tikTokHandles);
      setTiktokTags(listing.tikTokTags);
      setTiktoks(
        listing.requirements ? listing.requirements.requiredTikToks : 0
      );

      // Availability
      setListingType(listing.type);
      setStartDate(
        listing.startDate ? fromUnixTime(listing.startDate) : todayStartOfDay
      );
      setEndDate(listing.endDate ? fromUnixTime(listing.endDate) : null);
      setVisibleFromDate(
        listing.visibleDate ? fromUnixTime(listing.visibleDate) : null
      );
      setRequiredDaysNotice(listing.bookingOffset || 3);
      setDays(listing.days);
      setLocations(listing.locations.map((l) => l.id));

      setSchedules(listing.schedules);
      setTimeSlots(listing.eventTimeSlots);
      setDmnBookingTypeId(listing.dmnBookingTypeID || "");

      // Approval Settings
      setApprovalSettings(
        listing.approvalSettings
          ? listing.approvalSettings
          : {
              type: ApprovalType.ApprovalTypeManual,
            }
      );
      setCollinsAutoCreate(listing.collinsBookingAutoCreate);

      setListingStatus(listing.status);
      setApplicationsCount(listing.applicationsCount);
      setBookingLimitValue(listing.bookingLimitValue || null);
      setBookingLimitScope(listing.bookingLimitScope || null);
      setBookingLimitPeriod(listing.bookingLimitPeriod || null);
      setIsPrivate(listing.isPrivate);

      initialValuesRef.current = {
        name: listing.name,
        details: listing.details || "",
        images: listing.images,
        videoUrl: listing.featuredVideo || "",
        categories: listing.categories,
        dietaryPreferences: listing.dietaryPreferences || [],
        ageRestrictions: listing.minAge || null,
        phoneRequired: listing.requiresPhone,
        instagramOffers: listing.offers.filter(
          (o) => o.platform === Platform.Instagram
        ),
        instagramHandles: listing.instagramHandles,
        instagramTags: listing.instagramTags,
        requiredPosts: listing.requirements?.requiredPosts || 0,
        requiredReels: listing.requirements?.requiredReels || 0,
        requiredStories: listing.requirements?.requiredStories || 0,
        requirePostAndReel: listing.requirements?.requiredReelAndPost || false,
        isPrivate: listing.isPrivate,

        tiktokOffers: listing.offers.filter(
          (o) => o.platform === Platform.Tiktok
        ),
        tiktokHandles: listing.tikTokHandles,
        tiktokTags: listing.tikTokTags,
        requiredTikToks: listing.requirements?.requiredTikToks || 0,

        type: listing.type,
        startDate: listing.startDate
          ? fromUnixTime(listing.startDate)
          : todayStartOfDay,
        endDate: listing.endDate ? fromUnixTime(listing.endDate) : null,
        visibleFromDate: listing.visibleDate
          ? fromUnixTime(listing.visibleDate)
          : null,
        bookingOffset: listing.bookingOffset || 3,
        days: listing.days,
        locations: listing.locations.map((l) => l.id),
        schedules: listing.schedules,
        timeSlots: listing.eventTimeSlots,
        approvalSettings: listing.approvalSettings,
        dmnBookingTypeId: listing.dmnBookingTypeID || "",
        collinsAutoCreate: listing.collinsBookingAutoCreate ?? false,
        bookingLimitValue: listing.bookingLimitValue,
        bookingLimitScope: listing.bookingLimitScope,
        bookingLimitPeriod: listing.bookingLimitPeriod,
      };
      setHasUnsavedChanges(false);
    } else {
      setInstagramOffers([
        {
          platform: Platform.Instagram,
          minFollowers: 1500,
          maxFollowers: Infinity,
          maxGuests: 1,
          offer: "Complimentary meal",
          toggleProductValue: null,
          toggleProductId: null,
        },
      ]);
      setHasUnsavedChanges(false);
    }
  }, [listingData]);

  useEffect(() => {
    if (errors.offers.all) {
      setErrors({ ...errors, offers: { ...errors.offers, all: "" } });
    }
  }, [instagramOffers, tiktokOffers, errors]);

  useEffect(() => {
    const hasChanges = checkForChanges();
    setHasUnsavedChanges(hasChanges || false);
  }, [
    name,
    details,
    images,
    videoUrl,
    categories,
    dietaryPreferences,
    ageRestrictions,
    phoneRequired,
    instagramOffers,
    instagramHandles,
    instagramTags,
    tiktokOffers,
    tiktokHandles,
    tiktokTags,
    posts,
    reels,
    stories,
    tiktoks,
    requirePostAndReel,
    startDate,
    endDate,
    visibleFromDate,
    requiredDaysNotice,
    days,
    locations,
    schedules,
    timeSlots,
    dmnBookingTypeId,
    approvalSettings,
    collinsAutoCreate,
    bookingLimitValue,
    bookingLimitScope,
    bookingLimitPeriod,
    checkForChanges,
    isPrivate,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = ""; // Standard message for unsaved changes
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const unblock = history.block((location: Location, action: Action) => {
      if (hasUnsavedChanges && action === "PUSH") {
        const confirm = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (!confirm) {
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history]);

  useEffect(() => {
    if (brandBilling && !brandBilling.brand?.stripeCustomerId) {
      setRequiresUpgrade(true);
    } else {
      setRequiresUpgrade(false);
    }

    if (
      brandBilling &&
      brandBilling.brand?.billingStatus === BillingStatus.BillingStatusCancelled
    ) {
      setSubscriptionCancelled(true);
    } else {
      setSubscriptionCancelled(false);
    }
  }, [brandBilling]);

  if (listingLoading && !isNew) {
    return <Loading />;
  }

  if (createListing.isLoading || updateListing.isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <Modal isOpen={showCancelModal} maxWidth={620}>
        <H2 margin="0">Reactivate your subscription?</H2>
        <Text size="l" margin="s 0 xl">
          You'll need an active subscription to publish and go live. If you'd
          like to reactivate your subscription, please get in touch with the
          Joli team.
        </Text>
        <Text margin="0 0 s" weight="bold">
          With your subscription, you'll get:
        </Text>
        <ChecklistWrap margin="0 0 m">
          <Checklist header="Access vetted creators" />
          <Checklist header="Content gallery" />
          <Checklist header="Unlimited campaigns & events" />
          <Checklist header="Team management" />
          <Checklist header="Advanced reporting" />
          <Checklist header="Live chat & email support" />
        </ChecklistWrap>
        <StyledButtonWrap margin="xl 0 0">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowCancelModal(false);
            }}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              showNewMessages(
                "Hey Joli Team, can you help me reactivate my subscription?"
              );
            }}
          >
            Chat to us
          </Button>
        </StyledButtonWrap>
      </Modal>
      <Modal
        isOpen={showShareModal}
        maxWidth={580}
        onClose={() => {
          setShowShareModal(false);
          track("Listing Share Button Clicked", {
            listingId: id,
            brandId: brand?.id,
          });
        }}
      >
        <H2 margin="0 0 s">Share Campaign</H2>
        <Underline selected={true} />
        <Text size="l" margin="l 0 xl">
          Invite your favourite Influencers to apply — just share this link!
          <br />
          <br />
          Whether they’re already on Joli or not, they’ll be guided through the
          process, ensuring you get the same insights and tracking.
        </Text>
        <ShareLink link={`https://joliapp.com/invites/${id}`} />
        <Button
          size="s"
          onClick={() => setShowShareModal(false)}
          margin="xl 0 0 0"
        >
          Done
        </Button>
      </Modal>
      <Modal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(!showUpgradeModal)}
        maxWidth={620}
        // setIsOpen={setShowUpgrade}
      >
        <Flex direction="row" justify="space-between" margin="0 0 0">
          <View>
            <H2 margin="0">Ready to go live?</H2>
            <Text size="l" margin="s 0 l">
              Joli's influencers are waiting to promote your brand to their
              followers. You'll need an active subscription to publish and go
              live.
            </Text>
          </View>
        </Flex>
        <Text margin="0 0 s" weight="bold">
          With your subscription, you'll get:
        </Text>
        <ChecklistWrap margin="0 0 m">
          <Checklist header="Access vetted creators" />
          <Checklist header="Content gallery" />
          <Checklist header="Unlimited campaigns & events" />
          <Checklist header="Team management" />
          <Checklist header="Advanced reporting" />
          <Checklist header="Live chat & email support" />
        </ChecklistWrap>
        <StyledButtonWrap margin="xl 0 0">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowUpgradeModal(false);
            }}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              onSave(ListingStatus.StatusDraft, true);
              setShowUpgradeModal(false);
            }}
          >
            Manage subscription
          </Button>
        </StyledButtonWrap>
      </Modal>
      <Wrap>
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          margin="0 0 m"
        >
          <Flex justify="center">
            <BackLink margin="0" to="/b/listings">
              <Chevron direction="left" /> Back to Campaigns
            </BackLink>
          </Flex>
          {id ? (
            <View margin="0">
              <ShareButton onClick={() => setShowShareModal(true)}>
                Share Campaign
              </ShareButton>
            </View>
          ) : null}
        </Flex>

        <ListingDetails
          name={name}
          setName={handleNameChange}
          nameError={errors.name}
          details={details}
          setDetails={handleDetailsChange}
          detailsError={errors.details}
          dietaryPreferences={dietaryPreferences}
          setDietaryPreferences={setDietaryPreferences}
          images={images}
          setImages={handleImagesChange}
          imagesError={errors.images}
          phoneRequired={phoneRequired}
          setPhoneRequired={setPhoneRequired}
          ageRestrictions={ageRestrictions}
          setAgeRestrictions={setAgeRestrictions}
          categories={categories}
          setCategories={handleCategoriesChange}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          nameRef={nameRef}
          detailsRef={detailsRef}
          imagesRef={imagesRef}
          categoriesRef={categoriesRef}
          categoriesError={errors.categories}
          isPrivate={isPrivate}
          setIsPrivate={setIsPrivate}
        />

        <View margin="xl 0 0" ref={instagramOffersRef}>
          <InstagramOffers
            instagramOffers={instagramOffers}
            setInstagramOffers={setInstagramOffers}
            handles={instagramHandles}
            setHandles={setInstagramHandles}
            tags={instagramTags}
            setTags={setInstagramTags}
            posts={posts}
            setPosts={setPosts}
            requirePostAndReel={requirePostAndReel}
            setRequirePostAndReel={setRequirePostAndReel}
            reels={reels}
            setReels={setReels}
            stories={stories}
            setStories={setStories}
            instagramOffersRef={instagramOffersRef}
          />
        </View>

        <View margin="xl 0 0" ref={tiktokOffersRef}>
          <TikTokOffers
            tikTokOffers={tiktokOffers}
            setTikTokOffers={setTiktokOffers}
            handles={tiktokHandles}
            setHandles={setTiktokHandles}
            tags={tiktokTags}
            setTags={setTiktokTags}
            tiktoks={tiktoks}
            setTiktoks={setTiktoks}
            tiktokOffersRef={tiktokOffersRef}
          />
          {errors.offers.all && (
            <Text size="s" margin="l xs 0" colorPreset="error">
              {errors.offers.all}
            </Text>
          )}
        </View>

        <View margin="xl 0 0" ref={locationsRef}>
          <Availability
            listingType={listingType}
            setListingType={setListingType}
            days={days}
            setDays={handleDaysChange}
            daysError={errors.days}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            endDateError={errors.endDate}
            locations={locations}
            setLocations={setLocations}
            visibleFromDate={visibleFromDate}
            setVisibleFromDate={setVisibleFromDate}
            schedules={schedules}
            setSchedules={setSchedules}
            timeSlots={timeSlots}
            setTimeSlots={setTimeSlots}
            dmnBookingTypeId={dmnBookingTypeId}
            setDmnBookingTypeId={setDmnBookingTypeId}
            locationsRef={locationsRef}
            locationsError={errors.locations}
            requiredDaysNotice={requiredDaysNotice}
            setRequiredDaysNotice={setRequiredDaysNotice}
            numberOfVisitors={numberOfVisitors}
            bookingLimitPeriod={bookingLimitPeriod}
            setBookingLimitPeriod={setBookingLimitPeriod}
            bookingLimitValue={bookingLimitValue}
            setBookingLimitValue={setBookingLimitValue}
            bookingLimitScope={bookingLimitScope}
            setBookingLimitScope={setBookingLimitScope}
          />
        </View>

        <View margin="xl 0 0">
          <ApprovalSettings
            approvalSettings={approvalSettings}
            setApprovalSettings={handleApprovalSettingsChange}
            // autoApprovalLimit={autoApprovalLimit}
            // setAutoApprovalLimit={setAutoApprovalLimit}
            hasTikTokOffers={tiktokOffers.length > 0}
            internalBookingNotes={internalBookingNotes}
            setInternalBookingNotes={setInternalBookingNotes}
            collinsTypeId={dmnBookingTypeId}
            collinsBookingAutoCreate={collinsAutoCreate}
            setCollinsBookingAutoCreate={setCollinsAutoCreate}
            approvalSettingsError={errors.approvalSettings}
          />
        </View>
      </Wrap>

      <Preview
        description={details}
        locations={locations}
        instagramHandles={instagramHandles.map(removeAtSymbol)}
        tiktokHandles={tiktokHandles.map(removeAtSymbol)}
        isOpen={isPreview}
        onClose={() => setIsPreview(false)}
        reels={reels}
        posts={posts}
        stories={stories}
        tiktoks={tiktoks}
        images={images}
        instagramOffers={instagramOffers}
        tiktokOffers={tiktokOffers}
        name={name}
        dietaryPreferences={dietaryPreferences}
        ageRestrictions={ageRestrictions}
        videoUrl={videoUrl}
      />
      <Footer>
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <View>
            <RoundButton
              buttonType="secondary"
              onClick={() => validateName() && setIsPreview(true)}
            >
              Preview
            </RoundButton>
          </View>
          <div>
            <Flex direction="row" align="center">
              <View margin="0 m">
                <RoundButton
                  buttonType="secondary"
                  onClick={() =>
                    onSave(
                      listingStatus === ListingStatus.StatusScheduled // Scheduled is a calculated status
                        ? ListingStatus.StatusActive
                        : listingStatus
                    )
                  }
                >
                  {isNew ? "Save to Drafts" : "Save & Exit"}
                </RoundButton>
              </View>
              <View>
                {listingStatus === ListingStatus.StatusDraft && (
                  <RoundButton
                    style={{ backgroundColor: theme.color.constructive }}
                    onClick={() => onSave(ListingStatus.StatusActive)}
                  >
                    <Flex align="center">
                      <Flex
                        style={{
                          marginLeft: -8,
                          marginRight: 8,
                          marginTop: -2,
                        }}
                        align="center"
                        justify="center"
                      >
                        <PlayIcon height={14} width={14} opacity={1} />
                      </Flex>
                      {isFuture(startDate) &&
                      (!visibleFromDate ||
                        (visibleFromDate && isFuture(visibleFromDate)))
                        ? "Schedule"
                        : "Set Live"}
                    </Flex>
                  </RoundButton>
                )}
              </View>
              {!isNew && (
                <View margin="0 0 0 xs">
                  <Dropdown
                    size="s"
                    anchor="top end"
                    dropdownType="actions"
                    selectionMode="single"
                    disableOptionSort
                    renderLabel={() => {
                      return "Actions";
                    }}
                    selectedOptions={[]}
                    setSelectedOptions={(selectedOptions) => {
                      const selectedValue = selectedOptions[0];

                      if (selectedValue === "drafts") {
                        onSave(ListingStatus.StatusDraft);
                        return;
                      }

                      if (selectedValue === "delete") {
                        deleteListing.mutate(
                          { listingId: id },
                          {
                            onSuccess: () => {
                              toast.success("Campaign deleted");
                              history.push("/b/listings");
                            },
                          }
                        );
                        return;
                      }

                      if (selectedValue === "complete") {
                        onSave(ListingStatus.StatusExpired);
                      }

                      if (selectedValue === "duplicate") {
                        duplicateListing.mutate(
                          { id },
                          {
                            onSuccess: (res: DuplicateListingMutation) => {
                              toast.success("Campaign duplicated");
                              history.push(
                                `/b/listings/${res.duplicateListing.id}`
                              );
                            },
                          }
                        );
                      }
                    }}
                    options={[
                      {
                        label: "Duplicate",
                        value: "duplicate",
                      },
                      ...(listingStatus !== ListingStatus.StatusExpired
                        ? [
                            {
                              label: "Mark as Complete",
                              value: "complete",
                            },
                          ]
                        : []),
                      ...(listingStatus !== ListingStatus.StatusDraft
                        ? [
                            {
                              label: "Move to Drafts",
                              value: "drafts",
                            },
                          ]
                        : []),
                      ...(applicationsCount === 0 &&
                      listingStatus === ListingStatus.StatusDraft
                        ? [
                            {
                              label: "Delete Campaign",
                              value: "delete",
                            },
                          ]
                        : []),
                    ]}
                  />
                </View>
              )}
            </Flex>
          </div>
        </Flex>
      </Footer>
    </Page>
  );
};

const removeAtSymbol = (handle: string) => {
  return handle.replace("@", "");
};

export default ListingForm;

const Page = styled.div`
  width: 100%;
  position: relative;
`;

const Wrap = styled.div`
  padding: 0 ${(p: ThemeProp) => p.theme.spacing.l};
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: ${(p: ThemeProp) => p.theme.spacing.xxl};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p: ThemeProp) => p.theme.spacing.s};
    padding-bottom: 82px;
    margin-top: ${(p: ThemeProp) => p.theme.spacing.m};
  }
`;

export const ListingCard = styled(Card)`
  padding: ${(p: ThemeProp) => p.theme.spacing.xl};
  overflow: visible;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p: ThemeProp) => p.theme.spacing.l}
      ${(p: ThemeProp) => p.theme.spacing.l};
  }
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardFooter};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xxl};
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xxl};
  }
`;

const RoundButton = styled(Button)`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xl};
  font-size: ${(p) => p.theme.typography.size.s};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.xs};
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    font-size: ${(p) => p.theme.typography.size.m};
    padding: ${(p) => p.theme.spacing.m}) ${(p) => p.theme.spacing.xl};
  }
`;

const ShareButton = styled(Button)`
  line-height: 1em;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  font-weight: ${(p) => p.theme.typography.weight.semi};

  font-size: ${(p) => p.theme.typography.size.s};
`;
