import { MOBILE_BREAKPOINT } from "../config";
import styled, { css } from "../styles";
import { darkTheme, lightTheme } from "../styles/theme";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import { Text } from "./Text";

// Utility function to get initials from a name
export const getInitials = (name: string): string => {
  return name
    .split(" ")
    .map((part) => part[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

export const Avatar = styled.div<
  {
    url?: string | null;
    size?: number;
    withShadow?: boolean;
    isBlurry?: boolean;
    borderSize?: number;
    borderColor?: string;
  } & WithMarginProp
>`
  width: 36px;
  height: 36px;

  ${(p) =>
    p.size
      ? css`
          width: ${p.size}px;
          height: ${p.size}px;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            width: calc(${p.size}px - 4px);
            height: calc(${p.size}px - 4px);
          }
        `
      : null}

  background: #ffffff;
  ${(p) =>
    p.url
      ? css`
          background-image: url("${p.url}");
          background-size: cover;
        `
      : ""}

  border-radius: 100%;

  ${(p) =>
    p.borderSize || p.borderSize === 0
      ? css`
          border: ${p.borderSize}px solid #ffffff;
        `
      : `border: 3px solid #ffffff;`}

  ${(p) =>
    p.withShadow === undefined || p.withShadow
      ? css`
          box-shadow: 0px 4px 10px rgba(100, 57, 145, 0.17);
        `
      : ``}

  ${(p) =>
    p.isBlurry
      ? css`
          filter: blur(${p.theme.misc.blur});
          -webkit-filter: blur(${p.theme.misc.blur});
        `
      : ``};

  cursor: pointer;
  user-select: none;

  flex-shrink: 0;

  ${withMargin}
`;

Avatar.defaultProps = {
  url: "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg",
};

// Online status indicator component
const OnlineStatusDot = styled.div<{ darkMode: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => darkTheme.color.constructive};
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  right: 0;
  border: 2px solid
    ${(props) =>
      props.darkMode
        ? darkTheme.color.background.behind
        : lightTheme.color.card.background};
`;

// Styled component for InitialsAvatar
const InitialsAvatarContainer = styled.div<{
  size: number;
  backgroundColor?: string;
}>`
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.color.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
`;

interface InitialsAvatarProps extends WithMarginProp {
  name: string;
  size?: number;
  showOnlineStatus?: boolean;
  darkMode?: boolean;
  backgroundColor?: string;
  textColor?: string;
}

export const InitialsAvatar: React.FC<InitialsAvatarProps> = ({
  name,
  size = 34,
  showOnlineStatus = false,
  darkMode = false,
  backgroundColor,
  textColor = darkTheme.color.typography.text,
  ...marginProps
}) => {
  const initials = getInitials(name || "");

  return (
    <InitialsAvatarContainer
      size={size}
      backgroundColor={backgroundColor}
      {...marginProps}
    >
      <Text
        size={size >= 34 ? "m" : "xxs"}
        weight="semi"
        margin="0"
        style={{
          userSelect: "none",
          marginBottom: -2,
          color: "#fff",
        }}
      >
        {initials}
      </Text>
      {showOnlineStatus && <OnlineStatusDot darkMode={darkMode} />}
    </InitialsAvatarContainer>
  );
};

export default Avatar;
