import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MOBILE_BREAKPOINT } from "../config";
import {
  BookingPostMediaType,
  BookingPostStatus,
  BookingPostSubmissionsQuery,
  BookingPostType,
  GetBookingQuery,
  useUpdateBookingPostMutation,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { authSelectors } from "../store/auth/selector";
import styled from "../styles";
import Avatar, { InitialsAvatar } from "./Avatar";
import { SingleCheckBox } from "./Checkbox";
import { Chevron } from "./Chevron";
import { Button, ButtonWrap } from "./CTA";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { TextArea } from "./TextArea";
import { View } from "./View";

// Eventually split this up to work with Joli Studio too.

// Create a memoized component for individual comments
const VideoTimedComment = memo(
  ({
    comment,
    account,
    seekToTime,
  }: {
    comment: any;
    account: any;
    seekToTime: (time: number) => void;
  }) => {
    // Pre-format the time once during component creation
    const formattedTime =
      comment.time !== undefined ? formatTimestamp(comment.time) : null;
    const formattedCreatedAt = formatDistanceToNowStrict(
      fromUnixTime(comment.createdAt),
      { addSuffix: true }
    );

    return (
      <Flex align="flex-start" margin="l 0 0">
        <Avatar
          size={26}
          url={comment.author.avatar}
          borderSize={0}
          withShadow={false}
        />
        <View margin="0 0 0 s">
          <Text size="s" weight="bold" margin="0">
            {comment.author.firstName} {comment.author.lastName}{" "}
            <Text size="xxs" colorPreset="secondary" isInline margin="0 0 0 xs">
              {formattedCreatedAt}
            </Text>
          </Text>
          <Text size="xs" margin="0">
            {formattedTime && (
              <>
                <Timestamp onClick={() => seekToTime(comment.time as number)}>
                  {formattedTime}
                </Timestamp>{" "}
              </>
            )}
            {comment.text}
          </Text>
        </View>
      </Flex>
    );
  }
);

// Create a memoized component for the comments list
const VideoCommentList = memo(
  ({
    comments,
    account,
    seekToTime,
  }: {
    comments: any[];
    account: any;
    seekToTime: (time: number) => void;
  }) => {
    return (
      <CommentsContainer>
        {comments.map((comment, index) => (
          <VideoTimedComment
            key={index}
            comment={comment}
            account={account}
            seekToTime={seekToTime}
          />
        ))}
      </CommentsContainer>
    );
  }
);

const MediaSubmissionReview = ({
  submission,
  creator,
  onReview,
}: {
  submission: BookingPostSubmissionsQuery["bookingPosts"]["bookingPosts"][0];
  creator: GetBookingQuery["booking"]["creator"];
  onReview: () => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const client = useGqlClient();
  const updateBookingPostSubmission = useUpdateBookingPostMutation(client);

  const [comments, setComments] = useState<
    {
      time: number | undefined;
      text: string;
      author: {
        id: string;
        firstName?: string;
        lastName?: string;
        avatar?: string;
      };
      createdAt: number;
    }[]
  >([]);

  // Use a ref to track current time for display purposes without triggering re-renders
  const currentTimeRef = useRef(0);
  // Only use state for the displayed time in the timestamp checkbox
  const [displayTime, setDisplayTime] = useState(0);

  const [commentText, setCommentText] = useState("");
  const [includeCommentTime, setIncludeCommentTime] = useState(false);
  const account = useSelector(authSelectors.account);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");

  const isVideo =
    submission.mediaType === BookingPostMediaType.BookingPostMediaTypeVideo;

  const aspectRatio =
    submission.postType === BookingPostType.BookingPostTypeFeed
      ? "1:1"
      : "9:16";

  // Update the current time while playing - optimize to reduce re-renders
  const handleTimeUpdate = useCallback(() => {
    if (!isVideo || !videoRef.current) return;

    const newTime = videoRef.current.currentTime;
    currentTimeRef.current = newTime;

    // Only update state every second or when checkbox is checked
    // This prevents constant re-renders while keeping the UI updated
    if (Math.floor(newTime) !== Math.floor(displayTime) || includeCommentTime) {
      setDisplayTime(newTime);
    }
  }, [displayTime, includeCommentTime, isVideo]);

  // Add a comment at the current timestamp
  const addComment = useCallback(() => {
    if (!commentText.trim()) return;
    setComments((prevComments) => [
      ...prevComments,
      {
        time:
          isVideo && includeCommentTime ? currentTimeRef.current : undefined,
        text: commentText,
        author: {
          id: account?.id || "current-user",
          firstName: account?.firstName,
          lastName: account?.lastName,
        },
        createdAt: Math.floor(Date.now() / 1000),
      },
    ]);
    setCommentText("");
    setIncludeCommentTime(false);
  }, [commentText, includeCommentTime, account, isVideo]);

  // Seek to a comment timestamp - memoize to avoid recreation
  const seekToTime = useCallback(
    (time: number) => {
      if (!isVideo || !videoRef.current) return;
      videoRef.current.currentTime = time;
      videoRef.current.play();
    },
    [isVideo]
  );

  useEffect(() => {
    if (isVideo && videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }, [submission, isVideo]);

  // Memoize the formatted time display
  const formattedDisplayTime = formatTimestamp(displayTime);

  if (isModalOpen) {
    return (
      <View padding="m l">
        <Flex>
          <Text
            weight="bold"
            size="xl"
            margin={"0 0 0 0"}
            colorPreset="heading"
          >
            Request Changes
          </Text>
          <Text margin={"0 0 m 0"} colorPreset="secondary">
            Comments are the best way to leave specific feedback. If there's
            anything else you'd like to add, feel free to include it below.
          </Text>
          <TextArea
            rows={4}
            placeholder={"Any additional thoughts? (Optional)"}
            onChange={(e) => {
              setFeedback(e.currentTarget.value);
            }}
          />
          {error ? (
            <Text margin="0 0 0" colorPreset="error">
              {error}
            </Text>
          ) : null}

          <ButtonWrap margin="xl 0 0">
            <Button
              buttonType="secondary"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                updateBookingPostSubmission.mutate(
                  {
                    input: {
                      id: submission.id,
                      status:
                        BookingPostStatus.BookingPostStatusRevisionRequested,
                    },
                  },
                  {
                    onSuccess() {
                      onReview();
                    },
                  }
                );
              }}
            >
              Submit Feedback
            </Button>
          </ButtonWrap>
        </Flex>
      </View>
    );
  }

  return (
    <VideoContainer>
      <VideoSection postType={submission.postType}>
        {isVideo ? (
          <video
            ref={videoRef}
            controls
            onTimeUpdate={handleTimeUpdate}
            key={submission.mediaUrl}
          >
            <source src={submission.mediaUrl} type="video/mp4" />
          </video>
        ) : (
          <img
            src={submission.mediaUrl}
            alt={submission.mediaCaption || "Submitted image"}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </VideoSection>

      {/* Comments Section */}
      <SidePanel>
        <SidePanelHeader>
          <Avatar
            size={26}
            url={creator.preferredProfile?.avatar}
            borderSize={0}
            withShadow={false}
          />
          <View>
            <Text size="xs" colorPreset="heading" margin="0" weight="bold">
              @{creator.preferredProfile?.username}{" "}
              <Text size="xs" colorPreset="secondary" margin="0" isInline>
                ·{" "}
                {formatDistanceToNowStrict(fromUnixTime(submission.createdAt), {
                  addSuffix: true,
                })}
              </Text>
            </Text>
          </View>
        </SidePanelHeader>

        <SidePanelContainer>
          <CaptionWrap>
            <Text margin="0 0 0" size="xs">
              {submission.mediaCaption ? (
                submission.mediaCaption?.split("\n").map((t, i) => (
                  <Text margin="0" size="xs" key={i}>
                    {t}
                  </Text>
                ))
              ) : (
                <Text margin="0 0 0" size="xs">
                  No caption
                </Text>
              )}
            </Text>
          </CaptionWrap>
          <CommentsSection>
            {comments.length > 0 ? (
              <VideoCommentList
                comments={comments}
                account={account}
                seekToTime={seekToTime}
              />
            ) : null}
            <Flex align="flex-start" gap="s" margin="l 0 0">
              <div>
                <InitialsAvatar
                  name={`${account?.firstName} ${account?.lastName}`}
                  size={26}
                />
              </div>
              <CommentFormContainer>
                <CommentFormInput
                  type="text"
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  placeholder="Write a comment..."
                />
                <Flex align="center" justify="flex-end">
                  <div>
                    <Button
                      onClick={addComment}
                      isDisabled={!commentText.trim()}
                      buttonType="primary"
                      size="xs"
                    >
                      Comment
                    </Button>
                  </div>
                </Flex>
              </CommentFormContainer>
            </Flex>
            {isVideo && (
              <Flex align="center" justify="flex-end">
                <Flex
                  align="center"
                  gap="xs"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIncludeCommentTime(!includeCommentTime)}
                  justify="flex-end"
                  margin="s s 0 0"
                >
                  <SingleCheckBox checked={includeCommentTime} size="s" />
                  <div style={{ marginBottom: -2 }}>
                    <Text size="xs" colorPreset="secondary" margin="0 0 0 xs">
                      Comment at {formattedDisplayTime}
                    </Text>
                  </div>
                </Flex>
              </Flex>
            )}
          </CommentsSection>
        </SidePanelContainer>

        {/* Footer with Comment Input */}
        <SidePanelFooter>
          <Flex align="center" justify="center" direction="column">
            <Button
              buttonType="secondary"
              margin="0 0 s"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Request Changes{" "}
              <Chevron size="s" style={{ marginBottom: -1, marginLeft: 2 }} />
            </Button>
            <Button
              buttonType="primary"
              margin="0"
              onClick={() => {
                updateBookingPostSubmission.mutate(
                  {
                    input: {
                      id: submission.id,
                      status: BookingPostStatus.BookingPostStatusApproved,
                    },
                  },
                  {
                    onSuccess() {
                      onReview();
                    },
                  }
                );
              }}
            >
              Approve
            </Button>
          </Flex>
        </SidePanelFooter>
      </SidePanel>
    </VideoContainer>
  );
};

export default MediaSubmissionReview;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  max-height: 85vh;
  max-width: 100%;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    max-height: none;
  }
`;

const VideoSection = styled.div<{ postType: BookingPostType }>`
  max-height: 85vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  min-width: 400px;
  max-width: 500px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
    max-height: none;
  }

  video,
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    object-fit: contain;
  }

  /* Add a wrapper for the media to maintain aspect ratio */
  &::before {
    content: "";
    display: block;
    padding-top: ${(p) =>
      p.postType === BookingPostType.BookingPostTypeFeed
        ? "100%" /* 1:1 for feed posts */
        : "177.78%"}; /* 9:16 for reels/stories */
  }

  /* Position the media absolutely within the aspect ratio container */
  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 85vh;
  min-width: 400px;
  max-width: 500px;
  justify-content: flex-start;
  position: relative;
  background: ${(p) => p.theme.color.card.background};
`;

const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background: ${(p) => p.theme.color.card.callout};

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.color.typography.secondary}30;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}50;
    opacity: 1;
  }

  /* Show scrollbar when hovering or actively scrolling */
  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover,
  &:active {
    scrollbar-color: ${(p) => p.theme.color.typography.secondary}30 transparent;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SidePanelFooter = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  border-top: 1px solid ${(p) => p.theme.color.card.divider};
  background-color: ${(p) => p.theme.color.card.background};
  box-sizing: border-box;
  margin-top: auto;
`;

const CommentFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.m}
    ${(p) => p.theme.spacing.s};
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  position: relative;
  background: ${(p) => p.theme.color.card.background};
  transition: box-shadow 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  /* This will be applied when the input inside is focused */
  &:focus-within {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
    outline: none;
  }
`;

const CommentFormInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: ${(p) => p.theme.typography.size.s};
  color: ${(p) => p.theme.color.typography.text};
  background: transparent;
  padding: ${(p) => p.theme.spacing.xs} 0 ${(p) => p.theme.spacing.m};
`;

const Timestamp = styled.span`
  font-size: 13px;
  color: ${(p) => p.theme.color.constructive};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  background-color: ${(p) => p.theme.color.constructive}30;
  padding: 1px 8px 0px;
  border-radius: 5px;
  cursor: pointer;
`;

const SidePanelHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
  background-color: ${(p) => p.theme.color.card.background};
`;

const CaptionWrap = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
`;

const CommentsSection = styled.div`
  padding: 0 ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l};
`;

function formatTimestamp(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, "0");
  return `${minutes}:${seconds}`;
}

const StyledFlex = styled(Flex)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 500px;
`;
