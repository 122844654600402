import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import styled from "../styles";
import { lightTheme } from "../styles/theme";
import { Flex } from "./Flex";

interface TabLabel {
  label: string;
  hash?: string;
  onClick?: () => void;
}

type TabLabelType = string | TabLabel;

interface Props {
  labels: TabLabelType[];
  content: React.ReactNode[];
}

export const Tabs = (props: Props) => {
  const getInitialIndex = () => {
    const hash = window.location.hash.replace("#", "");
    const index = props.labels.findIndex((t) => {
      if (typeof t === "string") return t.toLowerCase() === hash;
      return t.hash === hash || t.label.toLowerCase() === hash;
    });
    return index >= 0 ? index : 0;
  };

  const handleTabChange = (index: number) => {
    const label = props.labels[index];

    // Only proceed with hash updates if we have a valid label
    if (typeof label === "string" || typeof label === "object") {
      // Determine the hash value
      const hash =
        typeof label === "string"
          ? label.toLowerCase()
          : label.hash || label.label.toLowerCase();

      // Only update the hash if we have a valid hash value
      if (hash) {
        // For string labels or objects without explicit updateHash=false
        if (typeof label === "string") {
          window.location.hash = hash;
        }

        // Handle onClick if it exists
        if (typeof label === "object" && label.onClick) {
          label.onClick();
        }
      }
    }
  };

  return (
    <Wrap>
      <Tab.Group defaultIndex={getInitialIndex()} onChange={handleTabChange}>
        <Tab.List>
          <ScrollFlex>
            {props.labels.map((t) => (
              <Tab as={Fragment} key={typeof t === "string" ? t : t.label}>
                {({ selected }) => (
                  <Flex
                    direction="column"
                    style={{ marginRight: lightTheme.spacing.xl }}
                  >
                    <StyledButton
                      selected={selected}
                      onClick={typeof t === "object" ? t.onClick : undefined}
                    >
                      {typeof t === "string" ? t : t.label}
                    </StyledButton>
                    <Underline selected={selected} />
                  </Flex>
                )}
              </Tab>
            ))}
          </ScrollFlex>
        </Tab.List>
        <Tab.Panels>
          {props.content.map((c, index) => (
            <Tab.Panel key={index}>{c}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Wrap>
  );
};

const ScrollFlex = styled(Flex)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Wrap = styled.div`
  width: 100%;
  overflow-x: hidden;

  div {
    :focus-visible {
      outline-width: 0px !important;
      border-width: 0px !important;
      outline: none !important;
    }

    :visible {
      outline-width: 0px !important;
      border-width: 0px !important;
      outline: none !important;
    }

    button {
      :focus-visible {
        outline-width: 0px !important;
        border-width: 0px !important;
        outline: none !important;
      }

      :visible {
        outline-width: 0px !important;
        border-width: 0px !important;
        outline: none !important;
      }
    }
  }
`;

const StyledButton = styled.button<{ selected: boolean }>`
  padding: ${(p) => p.theme.spacing.s} 0px 0;
  background-color: transparent;
  border-width: 0px !important;
  outline-width: 0px !important;
  font-size: ${(p) => p.theme.typography.size.m};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  color: ${(p) => p.theme.color.typography.heading};
  user-select: none;

  cursor: pointer;
`;

export const Underline = styled.div<{ selected?: boolean }>`
  width: ${(p) => p.theme.spacing.xxxl};
  height: 2.5px;
  border-radius: 4px;
  background-color: ${(p) =>
    p.selected ? p.theme.color.primary : "transparent"};
  margin-top: ${(p) => p.theme.spacing.xs};
`;
